import { Box } from '@material-ui/core';
import React, { ElementType, FunctionComponent } from 'react';
import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import { FormatXMLElementFn } from 'intl-messageformat';

interface ITranslationProps extends MessageDescriptor {
  component?: ElementType<string>;
  values?: Record<string, string | number | boolean | null | undefined | Date | JSX.Element | FormatXMLElementFn>;
}

const Translation: FunctionComponent<ITranslationProps> = props => {
  const intl = useIntl();

  return (
    <Box component={props.component ?? 'span'} data-translationkey={props.id}>
      {(intl.formatMessage({ id: props.id }) !== props.id || props.defaultMessage) && (
        <FormattedMessage {...(props as MessageDescriptor)} />
      )}
    </Box>
  );
};

export default Translation;
