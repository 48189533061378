import { ApolloError, useQuery } from '@apollo/client';
import useErrorHandler from '../useErrorHandler';
import translations from '../../graphql/queries/translations';
import { GetTranslations } from '../../graphql/queries/__generated__/GetTranslations';

export const useGqlTranslations: () => { loading: boolean; data: GetTranslations; error?: ApolloError } = () => {
  const { data, loading, error } = useQuery<GetTranslations>(translations.GET_TRANSLATIONS, {
    fetchPolicy: 'cache-first',
  });

  useErrorHandler(error);

  return { loading, data, error };
};
