import React from 'react';
import IPlatformConfig from '../../model/IPlatformConfig';
import PlatformConfigContext from '../../react/PlatformJsonnetContext';
import jsonnetApi from '../../api/jsonnetApi';
import IInitializerComponent from './IInitializerComponent';
import useWidgetContext from '../../react/useWidgetContext';

const PlatformJsonnetConfigProvider: IInitializerComponent<{ config: IPlatformConfig }> = {
  hooks: () => {
    const {
      configuration: { dimensions },
    } = useWidgetContext();

    return dimensions;
  },
  initialize: (dimensions: any) => {
    return jsonnetApi.getConfiguration(dimensions || {}).then((config: IPlatformConfig | void) => {
      return { config: config as IPlatformConfig };
    });
  },

  component: ({ children, config }) => {
    return <PlatformConfigContext.Provider value={config}>{children}</PlatformConfigContext.Provider>;
  },
};

export default PlatformJsonnetConfigProvider;
