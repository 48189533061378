import IWidgetConfiguration from '../../../../common/model/IWidgetConfiguration';

const DEFAULT_WIDGET_CONFIG: Partial<IWidgetConfiguration> = {
  dimensions: {
    brand: process.env.REACT_APP_BRAND_CODE,
    country: process.env.REACT_APP_COUNTRY,
    region: process.env.REACT_APP_REGION,
    language: process.env.REACT_DEFAULT_LANGUAGE,
  },
};

export default DEFAULT_WIDGET_CONFIG;
