import {
  DxpDataLayerEvent,
  DxpDataLayerEventNames,
  DxpDataLayerType,
} from "./customDatalayerInterface";
import { CustomDxpWindow } from "./custom.window";
import { getReferrer } from "./dataLayerHelpers";
import dxpDataLayerToggleVerification from "./dxpDataLayerToggleVerification";

declare let window: CustomDxpWindow;

const dxpDigitalDataAssignment = (data: DxpDataLayerType) => {
  const isDxpDataLayer = dxpDataLayerToggleVerification();
  if (isDxpDataLayer) {
    try {
      const lastIndex = window.dataLayer.length - 1;
      const lastEvent = window.dataLayer[lastIndex];
      const isLastIndex = lastEvent?.eventName === data.eventName;

      if (isLastIndex) {
        window.dataLayer[lastIndex] = {
          ...lastEvent,
          event: DxpDataLayerEvent.CONFIGURATOR,
          pageTitle: document.title,
          pageName: window.location.pathname,
          pageLocation: window.location.pathname,
          referralPage: getReferrer().from,
          ...data,
        };
      } else {
        window.dataLayer.push({
          event: DxpDataLayerEvent.CONFIGURATOR,
          pageTitle: document.title,
          pageName: window.location.pathname,
          pageLocation: window.location.pathname,
          referralPage: getReferrer().from,
          ...data,
        });
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log("DataLayer error", err);
    }
  }
};

export const updateDxpDataLayer = (data: DxpDataLayerType, updateKey: keyof DxpDataLayerType, updateValue: string): string[] | undefined => {
  if (updateKey === "accessories") {
    const filteredAccessories: DxpDataLayerType[] =
      window.dataLayer.filter((data: DxpDataLayerType) => {
        return data.eventName === DxpDataLayerEventNames.CONFIGURATOR_ACCESSORIES;
      }) || [];

    if (!filteredAccessories.length) {
      dxpDigitalDataAssignment({
        ...data,
        accessories: [updateValue],
      });

      return [updateValue];
    }
    let accessories = filteredAccessories[filteredAccessories.length - 1].accessories;

    if (accessories?.includes(updateValue)) {
      accessories = accessories.filter((accessory) => accessory != updateValue);
    } else {
      accessories?.push(updateValue);
    }

    const finalAccessories = Array.from(new Set(accessories));
    data.accessories = finalAccessories;

    dxpDigitalDataAssignment(data);

    return finalAccessories;
  }
};

export default dxpDigitalDataAssignment;
