import { createContext } from 'react';
import { UseRefsProps, ComplexAccordionRef } from '../hooks/useRefs';

type SalesEnablementContextType = {
  customizeVersion: number;
  featureCategoryRefs?: UseRefsProps;
  registerFeatureCategoryRef?: (key: string, ref: ComplexAccordionRef) => void;
  initializedWithStock: boolean;
  isInSalesEnablement: boolean;
  sendIsInCustomisePage?: (data: boolean) => void;
  sessionId: string | null;
  setFirstDealerOptionIndex?: (index: number) => void;
};

const SalesEnablementContext = createContext<SalesEnablementContextType>({
  customizeVersion: 0,
  initializedWithStock: false,
  isInSalesEnablement: false,
  sessionId: null,
});

export default SalesEnablementContext;
