import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useSalesEnablementContext from '../../../gqlCommon/hooks/useSalesEnablementContext';
import { ROUTES } from '../../constants/routes';

const useExposeSalesEnablement = () => {
  const history = useHistory();
  const { isInSalesEnablement, sendIsInCustomisePage } = useSalesEnablementContext();

  useEffect(() => {
    const unsubscribe = history.listen(() => {
      if (isInSalesEnablement) {
        const nextPath = history.location.pathname;
        const isCustomizePage = nextPath.includes(ROUTES.CONFIGURE_BASE);
        sendIsInCustomisePage(isCustomizePage);
      }
    });

    return () => unsubscribe();
  }, [history, isInSalesEnablement]);
};

export default useExposeSalesEnablement;
