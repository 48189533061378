import { MutableRefObject, useCallback, useState } from 'react';

export type ComplexAccordionRef = MutableRefObject<
  HTMLDivElement & {
    setExpanded: () => void;
  }
>;

export type UseRefsProps = Record<string, ComplexAccordionRef>;

const useRefs = () => {
  const [refs, setRefs] = useState<UseRefsProps>({});

  const register = useCallback((key: string, ref: ComplexAccordionRef) => {
    setRefs(prev => ({
      ...prev,
      [key]: ref,
    }));
  }, []);

  return {
    refs,
    register,
  };
};

export default useRefs;
