import { EventEmitter } from 'events';

export default class EventContainer extends EventEmitter {

  /**
   * Listeners that listen for all events.
   */
  protected allListeners: Array<(...args: any[]) => void> = [];

  /**
   * Register listener for any event.
   *
   * @param {Function} callback
   * @return {this}
   */
  public onEvery(callback: (...args: any[]) => void): this {
    this.allListeners.push(callback);

    return this;
  }

  /**
   * Emits events.
   *
   * @param {string | symbol} event
   * @param args
   *
   * @throws *
   *   In case of event == "error" the error argument object will be rethrown
   *   if there are not listeners.
   *
   * @return {boolean}
   */
  public emit(event: string | symbol, ...args: any[]): boolean {
    this.allListeners.forEach((callback: (...args: any[]) => void) => callback(event, ...args));

    return super.emit(event, ...args);
  }

  /**
   * @inheritDoc
   */
  public listenerCount(type: string | symbol): number {
    const count: number = super.listenerCount(type);

    return count + this.allListeners.length;
  }

  /**
   * Removes all listeners.
   *
   * @return {this}
   */
  public removeAllListeners(): this {
    super.removeAllListeners();
    this.allListeners = [];

    return this;
  }

}
