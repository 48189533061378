import { hot } from 'react-hot-loader/root';
import React, { FunctionComponent } from 'react';
import TagManager from 'react-gtm-module';
import ThemeProvider from './providers/ThemeProvider';
import SpcWidgetRouter from './SpcWidgetRouter';
import httpClient from '../api/httpClient';
import useWidgetContext from '../react/useWidgetContext';
import TranslationProvider from './providers/TranslationProvider';
import ParallelContextualizedInitializer from './ParallelContextualizedInitializer';
import PlatformConfigProvider from './providers/PlatformConfigProvider';
import PlatformJsonnetConfigProvider from './providers/PlatformJsonnetConfigProvider';
import MinimalLoader from './presentation/MinimalLoader';
import StoreProvider from './providers/StoreProvider';
import { ApolloProvider } from '@apollo/client';
import useApolloClient from '../hooks/useApolloClient';
import GqlTranslationsProvider from '../../gqlCommon/components/providers/GqlTranslationProvider';
import useGqlRestSwitch from '../hooks/useGqlRestSwitch';
import { ToastContainer } from 'react-toastify';
import useNewJsonnetApi from '../../../../common/utilities/useNewJsonnetApi';
import CONSTANTS from '../constants/constants';
import SalesEnablementContextProvider from '../../gqlCommon/provider/SalesEnablementContextProvider';

const SpcWidget: FunctionComponent<any> = () => {
  const { configuration } = useWidgetContext();
  const { client } = useApolloClient();
  const { isGqlApi } = useGqlRestSwitch();
  const useNewApi = useNewJsonnetApi(configuration.dimensions);

  httpClient.configure(configuration.cookieDomain, configuration.dimensions);
  TagManager.initialize({ gtmId: CONSTANTS.GTM_ID });

  // hack hotfix for bwm-chile will be remove when a final fix is in place
  const oneTimeClear = window.localStorage.getItem('oneTimeClear') ?? false;

  if (oneTimeClear === false) {
    window.localStorage.removeItem('cl_bmw-dis_finance_form_input');
    window.localStorage.setItem('oneTimeClear', 'true');
  }

  return (
    <ApolloProvider client={client}>
      <ParallelContextualizedInitializer
        initializers={[
          StoreProvider,
          ThemeProvider,
          useNewApi ? PlatformJsonnetConfigProvider : PlatformConfigProvider,
          isGqlApi ? GqlTranslationsProvider : TranslationProvider,
        ]}
        loader={<MinimalLoader />}
      >
        <SalesEnablementContextProvider>
          <SpcWidgetRouter />
          <ToastContainer limit={3} />
        </SalesEnablementContextProvider>
      </ParallelContextualizedInitializer>
    </ApolloProvider>
  );
};

export default hot(SpcWidget);
