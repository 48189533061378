import CONSTANTS from '../constants/constants';
import getSymbolFromCurrency from '../helpers/currencySymbolMap';

const locale = {
  getLocaleCode(language: string | null, country: string | null): string | null {
    if (!language || !country) {
      return null;
    }
    if (2 === language.length) {
      return `${language.toLowerCase()}-${country.toUpperCase()}`;
    }
    const languageParts = language.split('-');
    if (2 === languageParts.length) {
      return `${languageParts[0].toLowerCase()}-${languageParts[1].toUpperCase()}`;
    }

    return language;
  },

  getCurrencyLocaleCodeForLabel(currency: string): string {
    if (currency === 'USD') {
      return 'US' + getSymbolFromCurrency(currency);
    }
    return getSymbolFromCurrency(currency);
  },

  getCurrencyLocaleCode(language: string, country: string, currency: string): string {
    // Needed to display the dollar symbol in the required format.
    // @TODO: Consider moving this to platform config in case more cases like these are found.
    if ('USD' === currency && country !== 'ar' && country !== 'pe' && country !== 'cr' && country !== 'pa') {
      return CONSTANTS.USD_CURRENCY_DEFAULT_LOCALE_CODE;
    }

    return locale.getLocaleCode(language, country);
  },
};

export default locale;
