import React, { FunctionComponent, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import DeferredProductCatalogScreen from '../../productCatalog';
import GqlDeferredProductCatalogScreen from '../../gqlProductCatalog';
import DeferredProductVariantsScreen from '../../productVariants';
import { ROUTES } from '../constants/routes';
import DeferredCustomiseContainer from '../../customise';
import GqlDeferredCustomiseContainer from '../../gqlCustomise';
import DeferredStockListContainer from '../../stock/components/stockList';
import DeferredStockDetailContainer from '../../stock/components/stockDetail';
import GqlDeferredStockDetailContainer from '../../gqlStock/components/stockDetail';
import GqlDeferredStockListContainer from '../../gqlStock/components/stockList';
import DeferredTrimLevelsScreen from '../../trimLevels';
import usePlatformConfig from '../react/usePlatformConfig';
import useGqlRestSwitch from '../hooks/useGqlRestSwitch';
import useExposeSalesEnablement from '../hooks/navigation/useExposeSalesEnablement';
import useAzureMonitor from '../hooks/useAzureMonitor';
import dataLayerInitializationHandler from '../../../../common/utilities/dataLayerInitializationHandler';
import dxpDataLayerInitializationHandler from '../../../../common/utilities/dxpDataLayerInitializationHandler';
import useWidgetContext from '../react/useWidgetContext';
import { priceDisplaySpc } from '../service/priceDisplaySpc';
import { dxpPriceDisplaySpc } from '../service/dxpPriceDisplaySpc';
import { toggleDataLayer } from '../../../../common/service/toggleDataLayer';
import useCollectedUtm from '../service/useCollectedUtm';
import storageService from '../../../../common/service/storageService';

const SpcWidgetRoutes: FunctionComponent = () => {
  const { configuration } = useWidgetContext();
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(null);
  const {
    platformConfig: { isVariantsPageEnabled, stockPage, priceDisplay, dataLayer, dxpDataLayer },
  } = usePlatformConfig();
  const { isGqlApi } = useGqlRestSwitch();
  useExposeSalesEnablement();
  useAzureMonitor();
  useCollectedUtm();
  if (dataLayer) dataLayerInitializationHandler(configuration.dimensions);
  dxpDataLayerInitializationHandler(dxpDataLayer);

  useEffect(() => {
    if (location.pathname !== currentPath) {
      storageService.set('referrer', JSON.stringify({ to: location.pathname, from: currentPath }));
      setCurrentPath(location.pathname);
    }
  }, [location.pathname]);

  if (dataLayer) {
    priceDisplaySpc.set(configuration.dimensions, priceDisplay);
    toggleDataLayer.set(configuration.dimensions, dataLayer);
  }

  if (dxpDataLayer) {
    dxpPriceDisplaySpc.set(configuration.dimensions, priceDisplay);
  }

  return (
    <Switch>
      <Route
        exact={true}
        path={ROUTES.MODELS}
        component={isGqlApi ? GqlDeferredProductCatalogScreen : DeferredProductCatalogScreen}
      />
      {stockPage && (
        <Route
          exact={true}
          path={isGqlApi ? ROUTES.STOCK_LIST_GQL : ROUTES.STOCK_LIST}
          component={isGqlApi ? GqlDeferredStockListContainer : DeferredStockListContainer}
        />
      )}
      {stockPage && (
        <Route
          exact={true}
          path={isGqlApi ? ROUTES.STOCK_DETAIL_GQL : ROUTES.STOCK_DETAIL}
          component={isGqlApi ? GqlDeferredStockDetailContainer : DeferredStockDetailContainer}
        />
      )}
      {isGqlApi && <Route exact={true} path={ROUTES.CONFIGURE_GQL} component={GqlDeferredCustomiseContainer} />}
      <Route
        exact={true}
        path={ROUTES.CONFIGURE}
        component={isGqlApi ? GqlDeferredCustomiseContainer : DeferredCustomiseContainer}
      />
      {isVariantsPageEnabled && (
        <Route
          exact={true}
          path={isGqlApi ? ROUTES.TRIM_LEVELS : ROUTES.VARIANTS}
          component={isGqlApi ? DeferredTrimLevelsScreen : DeferredProductVariantsScreen}
        />
      )}
      <Redirect from={ROUTES.INDEX} to={ROUTES.MODELS} />
    </Switch>
  );
};

export default SpcWidgetRoutes;
