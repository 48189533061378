import React, { FC, ReactElement, useMemo } from 'react';
import themedErrorMessageStyles from './themedErrorMessageStyles';
import { Typography } from '@material-ui/core';
import Translation from '../../translation/Translation';
export interface IThemedErrorMessageProps {
  message?: string | ReactElement;
  error?: Error;
  fullPage?: boolean;
}

/**
 * Error message component that supports translation and MUI theming.
 */
const ThemedErrorMessage: FC<IThemedErrorMessageProps> = ({ message, error, fullPage }) => {
  const styles = themedErrorMessageStyles({ fullPage });

  const mainMessage = useMemo(() => {
    if (message && typeof message === 'string') {
      return <Translation id={message} />;
    } else if (message) {
      return message;
    } else if (error?.message && process.env.ENVIRONMENT !== 'prod') {
      return error.message;
    } else {
      return <Translation id={'spc.common.error.general'} />;
    }
  }, [message, error]);

  const secondaryMessage = useMemo(() => {
    if (message && error?.message && process.env.ENVIRONMENT !== 'prod') {
      return error.message;
    } else {
      return '';
    }
  }, [message, error]);

  return (
    <div className={styles.root}>
      <div className={styles.errorHeader}>
        <div className={styles.iconContainer}>
          <div className={styles.icon} />
        </div>
        <div className={styles.message}>
          <Typography variant={'body1'} data-test={'error:message'} className={styles.errorMessage}>
            {mainMessage}
          </Typography>
          <Typography className={styles.secondaryMessage} variant={'body2'} data-test={'error:secondary-message'}>
            {secondaryMessage}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default ThemedErrorMessage;
