/**
 * Service for managing DOM assets.
 */
export const assetManager = {

  isScriptLoaded(script: any): boolean {
    return !!document.head.querySelector(`script[src="${script}"]`) ||
      !!document.body.querySelector(`script[src="${script}"]`);
  },

  getFromDom(script: any): any {
    return !!document.head.querySelector(`script[src="${script}"]`) ?
      document.head.querySelector(`script[src="${script}"]`) :
      document.body.querySelector(`script[src="${script}"]`);
  },

  loadScript(script: any, scriptType?: string): Promise<any> {
    if (this.isScriptLoaded(script)) {
      const scriptInDom: any = this.getFromDom(script);
      const counter: number = Number.parseInt(scriptInDom.getAttribute('data-usage-count'), 10) || 0;

      scriptInDom.setAttribute(
        'data-usage-count',
        String(counter + 1),
      );

      return scriptInDom.onloadpromise;
    }

    const scriptTag: any = document.createElement('script');
    if (scriptType) {
      scriptTag.type = scriptType
    }
    scriptTag.src = script;

    scriptTag.onloadpromise = new Promise((resolve: any, reject: (error: any) => any) => {
      document.head.appendChild(scriptTag);
      scriptTag.onload = () => {
        return resolve();
      };
      scriptTag.onerror = () => reject(`Failed loading script: ${script}`);
    });

    return scriptTag.onloadpromise;
  },

  removeScript(script: any, force: boolean = false): Promise<any> {
    const scriptInDom: any = this.getFromDom(script);

    if (!scriptInDom) {
      return Promise.resolve();
    }

    if (force) {
      scriptInDom.parentNode.removeChild(scriptInDom);
      return Promise.resolve();
    }

    const counter: number = Number.parseInt(scriptInDom.getAttribute('data-usage-count'), 10) || 0;

    if (counter === 1) {
      scriptInDom.parentNode.removeChild(scriptInDom);
    } else {
      scriptInDom.setAttribute(
        'data-usage-count',
        String(counter - 1),
      );
    }

    return Promise.resolve();
  },

  loadStyle: (src: string): Promise<any> => {
    if (assetManager.hasStyle(src)) {
      // In case we have a link to the same style already we will set
      // a counter to keep track how many times we needed it, so when
      // removing only when the counter gets to 0 we remove the element.
      const styleInDom = document.head.querySelector(`[href="${src}"]`) as HTMLElement;
      const counter = Number.parseInt(styleInDom.getAttribute('data-usage-count') || '0', 10) || 0;

      styleInDom.setAttribute(
        'data-usage-count',
        String(counter + 1),
      );

      return Promise.resolve();
    }

    const style: HTMLLinkElement = document.createElement('link');
    style.href = src;
    style.rel = 'stylesheet';

    return new Promise<void>((resolve: () => void, reject: (error: any) => any) => {
      document.head.appendChild(style);
      style.onload = () => resolve();
      style.onerror = () => reject(`Failed loading css: ${src}`);
    });
  },

  hasStyle: (src: string): boolean => {
    return !!document.head.querySelector(`[href="${src}"]`);
  },
};
