import { useCallback, useEffect } from 'react';
import { STORAGE } from '../constants/storage';
import useWidgetContext from '../react/useWidgetContext';
import useIsGqlMarket from './useIsGqlMarket';
import CONSTANTS from '../constants/constants';
const deepEqual = require('fast-deep-equal/es6');

const useGqlRestSwitch = () => {
  const gqlApiLS = localStorage.getItem(STORAGE.GRAPHQL_LOCAL_STORAGE) === 'true';
  const { configuration } = useWidgetContext();
  const isGqlMarket: boolean = useIsGqlMarket(configuration) || configuration.gqlApi;

  // newly migrated markets don't contain the `gqlApi` flag in the widget config
  // this way we force these markets to use graphql until this check will be removed
  const newMarkets = [
    CONSTANTS.BE_SUBARU_AR_DIMENSIONS,
    CONSTANTS.BE_SZK_DIS_SG_DIMENSIONS,
    CONSTANTS.BE_SUBARU_CO_DIMENSIONS,
    CONSTANTS.BE_LEX_DIS_AU_HK_DIMENSIONS,
    CONSTANTS.BE_SUBARU_PE_DIMENSIONS,
  ];

  const isGqlApi =
    isGqlMarket &&
    (newMarkets.some(market => deepEqual(configuration.dimensions, market)) || configuration.gqlApi || gqlApiLS);

  useEffect(() => {
    const lsGqlFlag = localStorage.getItem(STORAGE.GRAPHQL_LOCAL_STORAGE);
    if (!lsGqlFlag || lsGqlFlag !== isGqlMarket?.toString()) {
      localStorage.setItem(STORAGE.GRAPHQL_LOCAL_STORAGE, isGqlMarket ? 'true' : 'false');
    }
  }, [configuration]);

  const toggleGqlApi = useCallback(() => {
    const w = window as any;
    const destroyWidget = w.destroyWidget ?? w.destroy;
    localStorage.setItem(STORAGE.GRAPHQL_LOCAL_STORAGE, gqlApiLS ? 'false' : 'true');

    if (!destroyWidget) {
      throw new Error('Failed to destroy widget! Missing destroy script.');
    }

    destroyWidget();
    w.Spc?.widgetLibrary.createWidget({ ...configuration, gqlApi: !gqlApiLS });
  }, [configuration]);

  return { toggleGqlApi, isGqlApi, isGqlMarket };
};

export default useGqlRestSwitch;
