import { FunctionComponent, useEffect } from 'react';

/**
 * This component applies a fix in the integration site for iframes
 * for the issue on mobile IOS regarding iframe sizing.
 * @see https://blog.codepen.io/2017/12/01/stupid-iframes-stupid-ios/
 * @TODO: Unify logic as it's duplicated across multiple widgets.
 */
const IframeIosMobileFix: FunctionComponent = () => {
  useEffect(() => {
    if (window === top) {
      return;
    }

    const html: HTMLElement = document.querySelector('html') as HTMLElement;
    const body: HTMLElement = document.querySelector('body') as HTMLElement;

    html.style.height = '100%';
    html.style.overflow = 'hidden';
    body.style.height = '100%';
    body.style.overflow = 'auto';
    (body.style as any)['-webkit-overflow-scrolling'] = 'touch';
  }, []);

  return null;
};

export default IframeIosMobileFix;
