import React from 'react';
import universal from 'react-universal-component';
import errorHandler from '../gqlCommon/service/errorHandler';
import MinimalLoader from '../common/components/presentation/MinimalLoader';
import ThemedErrorMessage from '../common/components/presentation/error/ThemedErrorMessage';
import IThemableComponent from '../common/theme/IThemableComponent';
import { OptionPriceMultiCurrencyClasses } from './components/presentational/prices/multiCurrency/optionPrice/optionPriceMultiCurrencyStyles';
import { SingleCurrencyCashClasses } from './components/presentational/prices/singleCurrency/cash/singleCurrencyCashStyles';
import { FinanceProductTabsClasses } from './components/customiseContainer/footer/PriceSection/FinanceProductTabs/financeProductTabsStyles';
import { PriceWithLabelStylesClasses } from './components/customiseContainer/footer/PriceSection/CarPrices/PriceWithLabel/priceWithLabelStyles';
import { StickyFooterClasses } from './components/customiseContainer/footer/StickyFooterContainerStyles';
import { ActionButtonsClasses } from './components/presentational/actionButtons/ActionButtonsStyles';
import { ButtonViewDetailsClasses } from '../common/components/sticky/ButtonViewDetailsStyles';
import { BaseConfiguratorLayoutClasses } from '../common/components/layouts/baseConfiguratorLayout/baseConfiguratorLayoutStyles';
import { ISummaryLinksStylesClasses } from './components/customiseContainer/customiseControls/summary/summaryLinks/summaryLinksStyles';
import { featureSectionTitleClass } from './components/customiseContainer/customiseControls/featureSection/featureSectionTitle/featureSectionTitleStyles';
import { TrimLevelNameClasses } from '../common/components/trimLevelsName/trimLevelName/trimLevelNameStyles';
import { ModelSelectorBaseClasses } from './components/customiseContainer/customiseControls/modelSelector/modelSectionContainer/modelSelectorBase/modelSelectorStyles';
import { CustomiseSectionTitleClasses } from './components/customiseContainer/customiseControls/simpleBrands/customiseSectionTitle/customiseSectionTitleStyles';
import { FeatureAccessoryDetailsModalClasses } from './components/customiseContainer/customiseControls/features/featureAccessory/featureAccessoryDetailsModal/featureAccessoryDetailsModalStyles';
import { VariantSelectorBaseClasses } from './components/customiseContainer/customiseControls/variantsSection/variantSelectorBase/variantSelectorStyles';
import { SpecificationsAccordionContentClasses } from './components/customiseContainer/customiseControls/specifications/specificationsAccordionContent/specificationsAccordionContentStyles';
import { SpecificationsDetailItemStylesClasses } from './components/customiseContainer/customiseControls/specifications/specificationsDetailItem/specificationsDetailItemStyles';
import { SpecificationsDetailListStylesClasses } from './components/customiseContainer/customiseControls/variantSelector/specificationsModal/specificationsDetailList/specificationsDetailListStyles';
import { ModelSelectorItemClasses } from './components/customiseContainer/customiseControls/modelSelector/modelSelectorItem/modelSelectorItemStyles';
import { SpecificationsAndFeaturesClasses } from './components/customiseContainer/customiseControls/specificationsAndFeatures/specificationsAndFeaturesStyles';
import { ISummaryStyleClasses } from '../customise/components/customiseContainer/customiseControls/summary/summarySection/SummaryStyles';
import { VariantSliderClasses } from '../customise/components/customiseContainer/customiseDisplay/variantSlider/sliderImage/SliderImageStyles';
import { IChangeModelStyleClasses } from './components/customiseContainer/customiseControls/changeModel/changeModelStyles';
import { NavigationElementClasses } from '../gqlCommon/components/navigation/navigationElement/navigationElementStyles';
import { FeatureSectionContainerClasses } from './components/customiseContainer/customiseControls/featureSection/featureSectionContainerStyles';
import { ThreeSixtyViewToggleClasses } from './components/customiseContainer/customiseDisplay/threeSixtyViewToggle/threeSixtyViewToggleStyles';
import { StockSliderClasses } from './components/StockLite/stockSlider/stockSliderStyles';
import { ModelSectionContainerClasses } from './components/customiseContainer/customiseControls/modelSelector/modelSectionContainer/modelSectionContainerStyles';
import { PanoramicInteriorClasses } from './components/customiseContainer/customiseDisplay/panoramicInterior/panoramicInteriorStyles';
import { StockLiteSliderClasses } from './components/StockLite/stockLiteListStyle';
import { CarCardStylesClasses } from './components/StockLite/CarCard/carCardStyles';
import { SpecDescriptionClasses } from './components/customiseContainer/customiseControls/specificationsAndFeatures/specifications/specificationsStyles';
import { ISummaryAdditionalSelectionsClasses } from './components/customiseContainer/customiseControls/summary/summaryAdditionalSelections/summaryAdditionalSelectionsStyles';
import { InformativeSectionContainerClasses } from './components/customiseContainer/footer/PriceSection/informativeSection/informativeSectionContainerStyles';
import { SingleCurrencyClasses } from './components/presentational/prices/singleCurrency/finance/singleCurrencyStyles';
import { SpecPackListClasses } from './components/customiseContainer/customiseControls/variantsSection/spacPackList/specPackListStyles';
import { FeatureColorPriceClasses } from './components/customiseContainer/customiseControls/features/featureColor/featureColorPrice/featureColorPriceStyles';
import { AccessoryPackModalClasses } from './components/customiseContainer/accessoryPacks/accessoryPackModal/accessoryPackModalStyles';
import { CustomiseOptionsTotalClasses } from './components/customiseContainer/customiseControls/simpleBrands/customiseOptions/customiseOptionsTotal/customiseOptionsTotalStyles';
import { ConfiguratorControlsLayoutClasses } from '../common/components/layouts/configuratorControlsLayout/configuratorControlsLayoutStyles';
import { AccessoryPackItemClasses } from './components/customiseContainer/accessoryPacks/accessoryPackItem/AccessoryPackItemStyles';
import { RateClasses } from './components/presentational/financeElements/rate/rateStyles';
import { IIndicativeNumberClasses } from '../gqlCommon/components/indicativeNumber/indicativeNumberStyles';
import { FeatureColorClasses } from './components/customiseContainer/customiseControls/features/featureColor/featureColorStyles';
import { ValueWithLabelClasses } from './components/customiseContainer/footer/PriceSection/CarPrices/percentageWithLabel/valueWithLabelStyles';
import { SummaryPricesStylesClasses } from './components/customiseContainer/customiseControls/summary/summaryContent/summaryPrices/summaryPricesStyles';

export interface ICustomiseThemableComponents {
  Customise_NavigationElement: IThemableComponent<NavigationElementClasses>;
  Customise_OptionPriceMultiCurrency: IThemableComponent<OptionPriceMultiCurrencyClasses>;
  Customise_SingleCurrencyCash: IThemableComponent<SingleCurrencyCashClasses>;
  GqlCustomise_FinanceProductTabs: IThemableComponent<FinanceProductTabsClasses>;
  Customise_PriceWithLabel: IThemableComponent<PriceWithLabelStylesClasses>;
  GqlCustomise_ActionButtons: IThemableComponent<ActionButtonsClasses>;
  GqlCustomise_StickyFooterContainer: IThemableComponent<StickyFooterClasses>;
  StickySummary_ButtonViewDetails: IThemableComponent<ButtonViewDetailsClasses>;
  Common_BaseConfiguratorLayout: IThemableComponent<BaseConfiguratorLayoutClasses>;
  GqlCustomise_SummaryLinks: IThemableComponent<ISummaryLinksStylesClasses>;
  Customise_CustomiseSection: IThemableComponent<ISummaryLinksStylesClasses>;
  Customise_Feature_Section_Title: IThemableComponent<featureSectionTitleClass>;
  Customise_TrimLevelName: IThemableComponent<TrimLevelNameClasses>;
  Customise_ModelSelector: IThemableComponent<ModelSelectorBaseClasses>;
  Customise_simpleBrands_CustomiseSectionTitle: IThemableComponent<CustomiseSectionTitleClasses>;
  Customise_simpleBrands_OptionsTotal: IThemableComponent<CustomiseOptionsTotalClasses>;
  Customise_Feature_Accessory_DetailsModal: IThemableComponent<FeatureAccessoryDetailsModalClasses>;
  Common_VariantSelector: IThemableComponent<VariantSelectorBaseClasses>;
  Common_ConfiguratorControlsLayout: IThemableComponent<ConfiguratorControlsLayoutClasses>;
  Customise_SpecificationsAccordionContent: IThemableComponent<SpecificationsAccordionContentClasses>;
  Customise_SpecificationsDetailItemStyles: IThemableComponent<SpecificationsDetailItemStylesClasses>;
  Customise_SpecificationsDetailListStyles: IThemableComponent<SpecificationsDetailListStylesClasses>;
  Customise_ModelSelectorItemClasses: IThemableComponent<ModelSelectorItemClasses>;
  Customise_SpecificationsAndFeatures: IThemableComponent<SpecificationsAndFeaturesClasses>;
  Customise_SpecDescription: IThemableComponent<SpecDescriptionClasses>;
  Customise_Summary: IThemableComponent<ISummaryStyleClasses>;
  Customise_Summary_ChangeModel: IThemableComponent<IChangeModelStyleClasses>;
  Customise_SliderImage: IThemableComponent<VariantSliderClasses>;
  Customise_ModelSectionContainer: IThemableComponent<ModelSectionContainerClasses>;
  Customise_FeatureSectionContainer: IThemableComponent<FeatureSectionContainerClasses>;
  Customise_ThreeSixtyViewToggle: IThemableComponent<ThreeSixtyViewToggleClasses>;
  GqlCustomise_StockSlider: IThemableComponent<StockSliderClasses>;
  Customise_PanoramicInterior: IThemableComponent<PanoramicInteriorClasses>;
  GqlCustomise_StockList: IThemableComponent<StockLiteSliderClasses>;
  StockLite_CarCard: IThemableComponent<CarCardStylesClasses>;
  Customise_SummaryAdditionalSelections: IThemableComponent<ISummaryAdditionalSelectionsClasses>;
  Customise_InformativeSectionContainer: IThemableComponent<InformativeSectionContainerClasses>;
  Customise_SingleCurrency: IThemableComponent<SingleCurrencyClasses>;
  GqlCustomise_SpecPackList: IThemableComponent<SpecPackListClasses>;
  GqlCustomise_Feature_ColorPrice: IThemableComponent<FeatureColorPriceClasses>;
  GqlCustomise_AccessoryPackModal: IThemableComponent<AccessoryPackModalClasses>;
  GqlCustomise_AccessoryPackItem: IThemableComponent<AccessoryPackItemClasses>;
  Customise_Rate: IThemableComponent<RateClasses>;
  Customise_IndicativeNumber: IThemableComponent<IIndicativeNumberClasses>;
  Customise_Feature_Color: IThemableComponent<FeatureColorClasses>;
  Calculator_PercentageWithLabel: IThemableComponent<ValueWithLabelClasses>;
  Customise_SummaryPrices: IThemableComponent<SummaryPricesStylesClasses>;
}

const DeferredCustomiseContainer = universal(
  () => import(/* webpackChunkName: "customise" */ './components/customiseContainer/CustomiseContainer'),
  {
    loading: (): JSX.Element => <MinimalLoader />,
    error: (props: { error: Error }) => {
      errorHandler.handleError(props.error);
      return <ThemedErrorMessage fullPage={true} message={'Failed loading customise screen.'} error={props.error} />;
    },
  },
);

export default DeferredCustomiseContainer;
