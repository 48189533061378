import { makeStyles } from '@material-ui/core/styles';
import { ISpcTheme } from '../../../theme/ISpcTheme';

export type ThemedErrorMessageClasses =
  | 'root'
  | 'errorHeader'
  | 'iconContainer'
  | 'icon'
  | 'message'
  | 'secondaryMessage'
  | 'errorMessage';

const themedErrorMessageStyles = makeStyles<ISpcTheme, { fullPage: boolean }, ThemedErrorMessageClasses>(
  ({ spacing, palette }) => ({
    root: {
      display: 'flex',
      justifyContent: 'center',
      margin: 'auto',
      maxWidth: 700,
      minWidth: 200,
    },
    errorHeader: {
      display: 'flex',
      flexDirection: ({ fullPage }) => (fullPage ? 'column' : 'row'),
      alignItems: 'center',
      justifyContent: ({ fullPage }) => (fullPage ? 'center' : 'left'),
    },
    iconContainer: {
      padding: spacing(2),
    },
    icon: {
      display: 'block',
      position: 'relative',
      width: 40,
      height: 40,
      border: `2px solid ${palette.error.main}`,
      borderRadius: 9999,
      '&:after, &:before': {
        content: '" "',
        display: 'block',
        width: 4,
        height: '60%',
        position: 'absolute',
        left: '50%',
        top: '50%',
        background: palette.error.main,
        borderRadius: 2,
        transform: 'translate(-50%, -50%) rotate(-45deg) ',
      },
      '&:before': {
        transform: 'translate(-50%, -50%) rotate(45deg)',
      },
      '&:after': {
        transform: 'translate(-50%, -50%) rotate(-45deg) ',
      },
    },
    message: {
      padding: spacing(2),
      paddingLeft: ({ fullPage }) => (fullPage ? spacing(2) : 0),
      paddingTop: ({ fullPage }) => (fullPage ? 0 : spacing(2)),
      textAlign: ({ fullPage }) => (fullPage ? 'center' : 'left'),
      width: '100%',
    },
    secondaryMessage: {
      opacity: 0.7,
      transform: 'scale(0.9)',
      wordBreak: 'break-word',
    },
    errorMessage: {
      color: palette.common.black,
    },
  }),
);

export default themedErrorMessageStyles;
