import React from 'react';
import universal, { UniversalComponent } from 'react-universal-component';
import errorHandler from '../gqlCommon/service/errorHandler';
import MinimalLoader from '../common/components/presentation/MinimalLoader';
import ThemedErrorMessage from '../common/components/presentation/error/ThemedErrorMessage';
import IThemableComponent from '../common/theme/IThemableComponent';
import { SelectedTrimLevelsNameContainerClasses } from './components/trimLevelsName/selectedTrimLevelsNameContainerStyles';
import { GenericCarNameClasses } from '../common/components/presentation/genericCarName/genericCarNameStyles';
import { TrimLevelItemActionsClasses } from './components/variantActions/trimLevelItemActionsStyles';
import { ExpandFeaturesClasses } from './components/expandFeatures/expandFeaturesStyles';
import { BasicInfoActionsClasses } from './components/trimLevelBasicInfo/basicInfoActions/basicInfoActionsStyles';
import { TrimLevelFeatureDetailsClasses } from './components/variantFeatureDetails/trimLevelFeatureDetailsStyles';
import { TrimLevelsModelBasicInfoClasses } from './components/variantBasicInfo/variantBasicInfoStyles';
import { ChangeModelLinkClasses } from '../gqlCommon/components/navigation/changeModelLink/changeModelLinkStyles';
import { TrimLevelNameClasses } from '../common/components/trimLevelsName/trimLevelName/trimLevelNameStyles';
import { EngineTrimLevelClasses } from './components/containers/engineTrimLevel/engineTrimLevelStyles';
import { SpecificationPackTrimLevelClasses } from './components/containers/specificationPackTrimLevel/specificationPackTrimLevelStyles';
import { EngineTrimFeatureClasses } from './components/containers/engineTrimLevel/engineTrimFeature/engineTrimFeatureStyles';
import { EngineTrimFeaturesClasses } from './components/containers/engineTrimLevel/engineTrimFeatures/engineTrimFeaturesStyles';
import { ImageCardClasses } from './components/variantImageCard/variantImageCardStyles';
import { TrimLevelItemFeaturesClasses } from './components/trimLevelItemFeatures/trimLevelItemFeaturesStyles';
import { FeatureTitleClasses } from './components/trimLevelItemFeatures/featureTitle/featureTitleStyle';
import { ImageContainerClasses } from '../gqlCommon/components/ImageContainer/imageContainerStyles';
import { NavigationBarClasses } from '../gqlCommon/components/navigation/navigationBar/navigationBarStyles';
import { ITrimLevelSpecificationLinkStylesClasses } from './components/trimLevelSpecificationLink/trimLevelSpecificationLinkStyles';
import { ViewMoreCTAClasses } from '../gqlCommon/components/presentation/viewMoreCTA/viewMoreCTAStyles';
import { VariantSliderContainerClasses } from './components/variantSliderContainer/variantSliderContainerStyles';
import { ModelFullNameClasses } from './components/modelFullName/ModelFullNameStyles';
import { IStockWidgetDisclaimerClasses } from '../gqlProductCatalog/components/stockWidgetDisclaimer/stockWidgetDisclaimerStyles';

export interface IVariantsComplexThemableComponents {
  TrimLevels_EngineTrimLevel: IThemableComponent<EngineTrimLevelClasses>;
  TrimLevels_SpecificationPackTrimLevel: IThemableComponent<SpecificationPackTrimLevelClasses>;
  TrimLevels_EngineTrimFeature: IThemableComponent<EngineTrimFeatureClasses>;
  TrimLevels_EngineTrimFeatures: IThemableComponent<EngineTrimFeaturesClasses>;
  TrimLevels_NameContainer: IThemableComponent<SelectedTrimLevelsNameContainerClasses>;
  TrimLevels_ItemFeatures: IThemableComponent<TrimLevelItemFeaturesClasses>;
  PVariantsComplex_GenericCarName: IThemableComponent<GenericCarNameClasses>;
  TrimLevels_TrimLevelItemActions: IThemableComponent<TrimLevelItemActionsClasses>;
  TrimLevels_DefaultModelBasicInfo: IThemableComponent<TrimLevelsModelBasicInfoClasses>;
  PVariantsComplex_ExpandFeatures: IThemableComponent<ExpandFeaturesClasses>;
  PVariantsComplex_BasicInfoActions: IThemableComponent<BasicInfoActionsClasses>;
  PVariantsComplex_VariantFeatureDetails: IThemableComponent<TrimLevelFeatureDetailsClasses>;
  PVariantsComplex_ModelFullName: IThemableComponent<ModelFullNameClasses>;
  TrimLevel_FeaturesTitle: IThemableComponent<FeatureTitleClasses>;
  PVariantsComplex_ImageCardStyles: IThemableComponent<ImageCardClasses>;
  Common_ChangeModelLink: IThemableComponent<ChangeModelLinkClasses>;
  Customise_TrimLevelName: IThemableComponent<TrimLevelNameClasses>;
  TrimLevels_SliderContainer: IThemableComponent<VariantSliderContainerClasses>;
  Customise_NavigationBar: IThemableComponent<NavigationBarClasses>;
  Common_ImageContainerStyles: IThemableComponent<ImageContainerClasses>;
  Customise_TrimLevel_Specification_Link: IThemableComponent<ITrimLevelSpecificationLinkStylesClasses>;
  Common_ViewMoreCTA: IThemableComponent<ViewMoreCTAClasses>;
  Customise_StockWidgetDisclaimer: IThemableComponent<IStockWidgetDisclaimerClasses>;
}

const DeferredTrimLevelsScreen: UniversalComponent<any> = universal<any>(
  () => import(/* webpackChunkName: "configure-screen" */ './components/ProductTrimLevelsScreen'),
  {
    loading: (): JSX.Element => <MinimalLoader />,
    error: (props: { error: Error }) => {
      errorHandler.handleError(props.error);
      return <ThemedErrorMessage fullPage={true} message={'Failed loading trim levels screen.'} error={props.error} />;
    },
  },
);

export default DeferredTrimLevelsScreen;
