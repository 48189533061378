import CONSTANTS from './constants';
import urlRoute from '../service/urlRoute';

const API = {
  MULTI_DIMENSION_CONFIG: urlRoute.joinUrl(
    CONSTANTS.PLATFORM_API_URL,
    'multi-dimensions/api/frontend/platform-features',
  ),

  STATUS: {
    NOT_FOUND: 404,
  },

  ERROR_CODE: {
    OPTION_NOT_FOUND: 404605,
  },

  IS_LOGGED_IN: urlRoute.joinUrl(CONSTANTS.DIGITAL_API_URL, '/api/customers/is-logged'),

  DEALERS_PATH: urlRoute.joinUrl(CONSTANTS.DIGITAL_API_URL, '/api/v1/dealers'),
};

export default API;
