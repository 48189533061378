import storageService from './storageService';
import IDimensions from '../model/IDimensions';

function getAuthenticationName(dimensions: IDimensions): string {
  const { brand, country, region } = dimensions;
  return `DXP_SAUT_${brand}_${country}_${region}`;
}

export const tokenService = {
  getCurrentToken: (dimensions: IDimensions) => {
    return storageService.get(getAuthenticationName(dimensions));
  },
  removeCurrentToken: (dimensions: IDimensions) => {
    return storageService.remove(getAuthenticationName(dimensions));
  },
};
