const urlRoute = {
  createUrl(
    routeString: string,
    params: Record<string, string | number> = {},
    query: { [key: string]: string | string[] } = {},
  ): string {
    Object.entries(params).forEach(([key, value]: [string, string | number]) => {
      routeString = routeString.replace(`{${key}}`, encodeURIComponent(value + ''));
    });

    // Arguments for react router paths
    Object.entries(params).forEach(([key, value]: [string, string | number]) => {
      routeString = routeString.replace(`:${key}`, encodeURIComponent(value + ''));
    });

    routeString = routeString.replace(/\[[^\]]+\]/, '');

    let queryString = '';
    Object.entries(query).forEach(([key, value]: [string, string | string[]]) => {
      if (!Array.isArray(value)) {
        queryString += key + '=' + encodeURIComponent(value) + '&';
      } else {
        value.forEach(v => (queryString += key + '=' + encodeURIComponent(v) + '&'));
      }
    });

    if (routeString[routeString.length - 1] === '/') {
      routeString = routeString.slice(0, -1);
    }

    if (queryString) {
      routeString += '?' + queryString.slice(0, -1);
    }

    return routeString;
  },

  joinUrl(...parts: string[]): string {
    return parts
      .filter(part => part !== '')
      .map(part => (!part ? '' : part.replace(/^\/+/, '').replace(/\/+$/, '')))
      .join('/');
  },

  getBaseUrl: (url: string): string => {
    const splittedUrl: string[] = url.split('/');

    return `${splittedUrl[0]}//${splittedUrl[2]}`;
  },
};

export default urlRoute;
