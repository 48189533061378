import { ITransformer } from '../model/ITransformer';
import { IPriceDisplay, IPlainPriceDisplay } from '../model/IPlatformConfig';

const priceDisplayConfigTransformer: ITransformer<IPlainPriceDisplay, any> = {
  from(data: any): IPriceDisplay {
    const priceDisplay: any = { secondaryOptions: false };

    Object.entries(data).forEach(([, value]: any) => {
      const priceDisplayOptions = value;

      Object.entries(priceDisplayOptions).forEach(([key, value]: any) => {
        priceDisplay[key] = value;
      });
    });

    return priceDisplay;
  },
  fromJsonnet(data: any): IPriceDisplay {
    const priceDisplay: any = { secondaryOptions: false };

    Object.entries(data).forEach(([key, value]: any) => {
      priceDisplay[key] = value;
    });

    return priceDisplay;
  },
};

export default priceDisplayConfigTransformer;
