const sessionStorageService = {
  set(key: string, value: string): void {
    sessionStorage.setItem(key, value);
  },
  get(key: string): string | null {
    return sessionStorage.getItem(key);
  },
  remove(key: string): void {
    sessionStorage.removeItem(key);
  },
};

export default sessionStorageService;
