import IPlatformConfig from '../model/IPlatformConfig';
import CONSTANTS from '../constants/constants';
import priceDisplayConfigTransformer from '../service/priceDisplayConfigTransformer';
import IDimensions from '../model/IDimensions';
import getJsonnetData from '../../../../common/utilities/getJsonnetData';
import { JourneyType } from '../../__generated__/globalTypes';
import { STOCK_SUMMARY_DISPLAY } from '../../../../common/constants/stockSummaryDisplay';

const { APP_CONFIG_BASE_URL } = CONSTANTS;

const jsonnetApi = {
  getConfiguration: async ({ brand, region, country, language }: IDimensions): Promise<void | IPlatformConfig> => {
    try {
      const response = await (await getJsonnetData(APP_CONFIG_BASE_URL, brand, region, country, language)).json();
      const { platformFeatures } = response;

      if (!platformFeatures) {
        throw new Error(`Missing spc feature flags platform configuration.`);
      }

      return {
        spcDealersFileName: platformFeatures.spcDealersFileName,
        isVariantsPageEnabled: platformFeatures.variantsPage === true,
        isFuelFilteringEnabled: platformFeatures.fuelFilter === true,
        isBodyStyleFilteringEnabled: platformFeatures.bodyStyleFilter === true,
        isTransmissionFilteringEnabled: platformFeatures.transmissionFilter === true,
        isPriceSortingEnabled: platformFeatures.carPriceSorting === true,
        isDealerSelectionEnabled: platformFeatures.dealerSelectionIntegration === true,
        isSaveOfferEnabled: platformFeatures.saveOffer === true,
        isFinanceEnabled: platformFeatures.financeIntegration === true,
        isCheckoutLiteEnabled: platformFeatures.spcCheckoutLiteIntegration === true,
        showGovernmentCharges: platformFeatures.showGovernmentCharges === true,
        hideSubmitWhenNoStock: platformFeatures.hideSubmitWhenNoStock === true,
        isComplexModel: platformFeatures.complexModel === true,
        gqlApi: platformFeatures.gqlApi === true,
        stockPage: platformFeatures.stock,
        priceDisplay: priceDisplayConfigTransformer.fromJsonnet(response?.priceDisplay),
        catalogTrimFeatures: platformFeatures.catalogTrimFeatures,
        engineTrimFeatures: platformFeatures.engineTrimFeatures,
        isCheckout3Enabled: platformFeatures.isCheckout3Enabled === true,
        isCheckout4Enabled: platformFeatures.isCheckout4Enabled === true,
        showThreeSixtyImages: platformFeatures.showThreeSixtyImages,
        first360Image: platformFeatures.first360Image,
        spcFullSpecificationUrl: platformFeatures.spcFullSpecificationUrl,
        stockVersion: platformFeatures.stockVersion,
        isOrderOnlineEnabled: platformFeatures.orderOnline === true,
        myAccountDxpIntegration: platformFeatures.myAccountDxpIntegration === true,
        insuranceIntegration: platformFeatures.insuranceIntegration === true,
        showInteriorAndExteriorPrice: platformFeatures.showInteriorAndExteriorPrice === true,
        showRefundableAmount: platformFeatures.showRefundableAmount === true,
        hideComparisonRate: platformFeatures.hideComparisonRate === true,
        hideVariantName: platformFeatures.hideVariantName === true,
        configurator: {
          summaryShowTaxes: response?.configurator30?.summaryShowTaxes,
          defaultCheckoutJourney:
            response?.configurator30?.defaultCheckoutJourney || JourneyType.continue_in_dealership,
        },
        specificationsBrochureUrl: platformFeatures.specificationsBrochureUrl,
        additionalInfoGFV: platformFeatures.additionalInfoGFV,
        hourDisableOrderNowFromTo: platformFeatures.hourDisableOrderNowFromTo,
        azureMonitoring: platformFeatures.azureMonitoring,
        stockApiPing: platformFeatures.stockApiPing,
        disableInitWithStockDisabledCategories: platformFeatures.disableInitWithStockDisabledCategories,
        hideCustomiseModelSectionInSalesEnablement: platformFeatures.hideCustomiseModelSectionInSalesEnablement,
        clearInsuranceBlockInputInSalesEnablementNewDeal:
          platformFeatures.clearInsuranceBlockInputInSalesEnablementNewDeal,
        showIndicativeDeliveryTimeframe: platformFeatures.showIndicativeDeliveryTimeframe === true,
        stockSummaryDisplay: platformFeatures.stockSummaryDisplay || STOCK_SUMMARY_DISPLAY.NONE,
        dataLayer: platformFeatures.dataLayer === true,
        dxpDataLayer: platformFeatures.dxpDataLayer === true,
        showExtraDetailsInNewTab: platformFeatures.showExtraDetailsInNewTab === true,
        myAccount: {
          miniMyAccountConfig: response?.myAccount?.miniMyAccountConfig,
        },
        hideSelectionsSection: platformFeatures.hideSelectionsSection === true,
        useDxpFc: platformFeatures.useDxpFc === true,
      };
    } catch (e) {
      throw new Error(`Unable to get platform configuration.`);
    }
  },
};

export default jsonnetApi;
