const deepEqual = require('fast-deep-equal/es6');
import { useMemo } from 'react';
import CONSTANTS from '../constants/constants';
import IWidgetConfiguration from '../../../../common/model/IWidgetConfiguration';

const useIsGqlMarket: (config: IWidgetConfiguration) => boolean = config => {
  const gqlMarkets = [
    CONSTANTS.BE_SUBARU_CL_DIMENSIONS,
    CONSTANTS.BE_SUBDIS_AU_DIMENSIONS,
    CONSTANTS.BE_JAG_PL_DIMENSIONS,
    CONSTANTS.BE_BMW_DIS_CL_DIMENSIONS,
    CONSTANTS.BE_BMW_DIS_PE_DIMENSIONS,
    CONSTANTS.BE_MOT_DIS_CL_DIMENSIONS,
    CONSTANTS.BE_MOT_DIS_PE_DIMENSIONS,
    CONSTANTS.BE_MINI_DIS_CL_DIMENSIONS,
    CONSTANTS.BE_MINI_DIS_PE_DIMENSIONS,
    CONSTANTS.BE_MER_DIS_CO_DIMENSIONS,
    CONSTANTS.BE_SUB_DIS_NZ_DIMENSIONS,
    CONSTANTS.BE_HIN_DIS_CO_DIMENSIONS,
    CONSTANTS.BE_SZK_DIS_PA_DIMENSIONS,
    CONSTANTS.BE_SZK_DIS_CR_DIMENSIONS,
    CONSTANTS.BE_SZK_DIS_SG_DIMENSIONS,
    CONSTANTS.BE_TOY_DIS_BN_DIMENSIONS,
    CONSTANTS.BE_DFS_DIS_PE_DIMENSIONS,
    CONSTANTS.BE_DFS_DIS_CL_DIMENSIONS,
    CONSTANTS.BE_TOY_DIS_SG_DIMENSIONS,
    CONSTANTS.BE_TOY_DIS_HK_DIMENSIONS,
    CONSTANTS.BE_TOY_DIS_ZH_HK_DIMENSIONS,
    CONSTANTS.BE_SUB_DIS_AU_DIMENSIONS,
    CONSTANTS.BE_GLY_DIS_CL_DIMENSIONS,
    CONSTANTS.BE_GRW_DIS_HK_DIMENSIONS,
    CONSTANTS.BE_GRW_DIS_ZH_HK_DIMENSIONS,
    CONSTANTS.BE_SUBARU_AR_DIMENSIONS,
    CONSTANTS.BE_SUBARU_PE_DIMENSIONS,
    CONSTANTS.BE_SUBARU_CO_DIMENSIONS,
    CONSTANTS.BE_LEX_DIS_AU_HK_DIMENSIONS,
    CONSTANTS.BE_MER_PAS_EC_DIMENSIONS,
    CONSTANTS.BE_BYD_DIS_NL_BE_DIMENSIONS,
    CONSTANTS.BE_BYD_DIS_FR_BE_DIMENSIONS,
    CONSTANTS.BE_BYD_DIS_EN_BE_DIMENSIONS,
  ];

  return useMemo(() => !!gqlMarkets.find(market => deepEqual(config.dimensions, market)), []);
};

export default useIsGqlMarket;
