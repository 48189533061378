import {CustomWindow} from "./custom.window";
import dataLayerToggleVerification from "./dataLayerToggleVerification";
import {DataLayerType} from "./customDatalayerInterface";
import IDimensions from "../model/IDimensions";

declare let window: CustomWindow;


const dataLayerInitializationHandler = (dimension: IDimensions): void => {
    const isDataLayer = dataLayerToggleVerification(dimension);
    if (isDataLayer) {
        window.digitalData = window.digitalData || {
            events: [],
            currentState: {},
            pushAndUpdate: (elem: any) => {
                window.digitalData.pushAndUpdate(elem);
            }
        };
        window.dataLayer = window.dataLayer || [];
        window.digitalData.events = window.digitalData.events || [];

        window.digitalData.pushAndUpdate = function (elem) {
                const index = window.digitalData.events.findIndex((item: DataLayerType) => {
                    return item && (item.form && (item.form.stage === elem.form.stage) &&  (item.event === elem.event))
                });
                if (index > -1) {
                    if (window.digitalData.events[index].event === elem.event) {
                        window.digitalData.events[index] = elem
                    } else {
                        window.digitalData.events.push(elem)
                    }
                } else {
                    window.digitalData.events.push(elem)
                }
                window.dataLayer.push(Object.assign({}, elem))

                window.digitalData.currentState = cloneObject(window.digitalData.currentState, elem)

        }
    }

};

/*Declare cloneObject function - required for populating digitalData.currentState whenever the digitalData.events.pushAndUpdate method is called*/
export const cloneObject = function (target: { [x: string]: any }, source: { [x: string]: any }) {
    for (const i in source) {
        if (null !== source[i] && 'object' === typeof source[i]) {
            target[i] = target[i] || {};
            target[i] = cloneObject(target[i], source[i]);
        } else {
            target[i] = source[i];
        }
    }
    return target;
};

export default dataLayerInitializationHandler