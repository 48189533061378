import errorHandler from '../service/errorHandler';
import { useEffect } from 'react';

const useErrorHandler = (error: any) => {
  useEffect(() => {
    if (error) {
      errorHandler.handleError(error);
    }
  }, [error]);
};

export default useErrorHandler;
