import IDimensions from "../model/IDimensions";

function buildId(dimension: IDimensions): string {
  return `${dimension.country}_${dimension.brand}_dataLayer`;
}

export const toggleDataLayer = {
  get: (dimension: IDimensions) => {
    return JSON.parse(localStorage.getItem(buildId(dimension)) ?? 'null');
  },
  set: (dimension: IDimensions, value: boolean) => {
    localStorage.setItem(buildId(dimension), JSON.stringify(value));
  },
};
