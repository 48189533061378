import IDimensions from '../../../gqlCommon/model/IDimensions';
import { priceDisplaySpc } from '../../service/priceDisplaySpc';
import { IPriceOverride } from '../../../../../common/model/IPrice';

type PriceExtractionHandlerReturnType = {
  primaryPrice: IPriceOverride;
  secondaryPrice: IPriceOverride;
};

const priceExtractionHandler = (prices: IPriceOverride[], dimension: IDimensions): PriceExtractionHandlerReturnType => {
  const priceDisplay = JSON.parse(priceDisplaySpc.get(dimension)) || null;
  const defaultPrice = {
    money: {
      amount: 0,
      currency: '',
    },
    type: '',
  };

  let isPrimaryPrice: (price: IPriceOverride) => boolean;
  let isSecondaryPrice: (price: IPriceOverride) => boolean;

  if (priceDisplay) {
    const isDisplayPriceByType = priceDisplay.displayPriceByType;

    if (isDisplayPriceByType) {
      isPrimaryPrice = price => price.type === priceDisplay.primary.priceType;
      isSecondaryPrice = price => price.type === priceDisplay?.secondary?.priceType;
    } else {
      isPrimaryPrice = price => price.money.currency === priceDisplay.primary.currency;
      isSecondaryPrice = price => price.money.currency === priceDisplay?.secondary?.currency;
    }

    const getPrimaryPrice = (prices: IPriceOverride[]) => prices?.find(price => isPrimaryPrice(price));
    const getSecondaryPrice = (prices: IPriceOverride[]) => prices?.find(price => isSecondaryPrice(price));

    const primaryPrice = getPrimaryPrice(prices) ?? defaultPrice;
    const secondaryPrice = getSecondaryPrice(prices) ?? defaultPrice;

    return { primaryPrice, secondaryPrice };
  }
};

export default priceExtractionHandler;
