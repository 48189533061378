/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum DepositType {
  absolute = 'absolute',
  percentage = 'percentage',
}

export enum FacetOptionStatus {
  DISABLED = 'DISABLED',
  ENABLED = 'ENABLED',
  SELECTED = 'SELECTED',
}

export enum FeatureType {
  multi = 'multi',
  mutex0 = 'mutex0',
  mutex1 = 'mutex1',
}

export enum FinanceFormType {
  cash = 'cash',
  finance = 'finance',
}

export enum FinanceResultType {
  cash = 'cash',
  error = 'error',
  finance = 'finance',
  na = 'na',
  not_applicable = 'not_applicable',
}

export enum JourneyType {
  continue_in_dealership = 'continue_in_dealership',
  online_order = 'online_order',
  pre_order_now = 'pre_order_now',
  reserve_online = 'reserve_online',
  save_my_build = 'save_my_build',
}

export enum LoanTermPeriodUnit {
  month = 'month',
}

export interface FeatureIdsInput {
  values?: (string | null)[] | null;
  selected?: (string | null)[] | null;
  added?: (string | null)[] | null;
  removed?: (string | null)[] | null;
  included?: (string | null)[] | null;
}

export interface Filters {
  trimCode: any;
  bodyStyleCode?: string | null;
  variantCode?: string | null;
  modelCode?: string | null;
  engineCode?: string | null;
  specPackCode?: string | null;
}

export interface FinanceFormInput {
  paymentType: FinanceFormType;
  financeProductId?: string | null;
  financePackageId?: string | null;
  term?: number | null;
  deposit?: number | null;
  interestRate?: number | null;
  mileage?: number | null;
  partExchangeId?: string | null;
  ageRange?: string | null;
  homeOwner?: string | null;
  residualValue?: number | null;
  paymentFrequency?: string | null;
}

export interface FinanceInput {
  journeyType: FinanceFormType;
  financeProductId?: string | null;
  term?: number | null;
}

export interface InsuranceFormInput {
  formData: string;
}

export interface SearchInput {
  bodyStyleType?: (string | null)[] | null;
  fuelType?: (string | null)[] | null;
  transmissionType?: (string | null)[] | null;
  bodytypeType?: (string | null)[] | null;
  maximumPowerType?: (string | null)[] | null;
  vehicleGrossWeightType?: (string | null)[] | null;
  grossWeightCapacityType?: (string | null)[] | null;
  usableCabToAxleType?: (string | null)[] | null;
  dimensionsFromWheelTrackType?: (string | null)[] | null;
  seriesType?: (string | null)[] | null;
  modelType?: (string | null)[] | null;
  bodyStyleShortNameType?: (string | null)[] | null;
  minPrice?: number | null;
  maxPrice?: number | null;
  vehicleInStockType?: (string | null)[] | null;
  division?: (string | null)[] | null;
}

export interface SelectedCarForDeal {
  id?: any | null;
  filters?: Filters | null;
  selectedFeatureIds?: FeatureIdsInput | null;
  configurationSourceUrl: string;
  isStock?: boolean | null;
}

export interface UTMDetailsInput {
  source: string;
  medium: string;
  campaign: string;
  term: string;
  content: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
