import React from 'react';
import universal from 'react-universal-component';
import errorHandler from '../../../common/service/errorHandler';
import ThemedErrorMessage from '../../../common/components/presentation/error/ThemedErrorMessage';
import MinimalLoader from '../../../common/components/presentation/MinimalLoader';

const DeferredStockDetailContainer = universal(
  () => import(/* webpackChunkName: "stock" */ './layout/StockDetailLayout'),
  {
    loading: (): JSX.Element => <MinimalLoader />,
    error: (props: { error: Error }) => {
      errorHandler.handleError(props.error);
      return <ThemedErrorMessage fullPage={true} message={'Failed loading detail view screen.'} error={props.error} />;
    },
  },
);

export default DeferredStockDetailContainer;
