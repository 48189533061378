export default interface ISelectedFeaturesInputVar {
  selected: string[];
  added: string[];
  removed: string[];
}

export const SelectedFeaturesInputVarDefaultValue: ISelectedFeaturesInputVar = {
  selected: [],
  added: [],
  removed: [],
};
