import IDimensions from "../model/IDimensions";
import { IPriceOverride } from "../model/IPrice";
import storageService from "../service/storageService";

export type SaveDXPDataLayerDataOnLocalStoragePayload = {
  dxpDataLayer?: boolean;
  model?: string | null;
  totalPrice?: Partial<IPriceOverride>[] | null;
  vehiclePrice?: Partial<IPriceOverride>[] | null;
  accessoriesPrice?: Partial<IPriceOverride>[] | null;
  versions?: string | null;
  keyspecs?: string | null;
  dimensions?: IDimensions;
};

function buildId(dimensions: IDimensions): string {
  return `${dimensions.country}_${dimensions.brand}_dxpDataLayerInfo`;
}

export const saveDXPDataLayerDataOnLocalStorage = {
  get: (dimension: IDimensions) => {
    return storageService.get(buildId(dimension)) ?? null;
  },
  set: (dimension: IDimensions, value: SaveDXPDataLayerDataOnLocalStoragePayload) => {
    return storageService.set(buildId(dimension), JSON.stringify(value));
  },
};

export const saveDXPLayerDataOnLocalStorage = (payload: SaveDXPDataLayerDataOnLocalStoragePayload) => {
  if (payload && payload.dxpDataLayer && payload.dimensions) {
    const { model, totalPrice, vehiclePrice, accessoriesPrice, versions, keyspecs } = payload;
    const localStoragePayload = {
      model: model ?? null,
      totalPrice: totalPrice ?? null,
      vehiclePrice: vehiclePrice ?? null,
      accessoriesPrice: accessoriesPrice ?? null,
      versions: versions ?? null,
      keyspecs: keyspecs ?? null,
    };
    saveDXPDataLayerDataOnLocalStorage.set(payload?.dimensions, localStoragePayload);
  }
};
