import React from 'react';
import universal, { UniversalComponent } from 'react-universal-component';
import errorHandler from '../common/service/errorHandler';
import MinimalLoader from '../common/components/presentation/MinimalLoader';
import ThemedErrorMessage from '../common/components/presentation/error/ThemedErrorMessage';
import IThemableComponent from '../common/theme/IThemableComponent';
import { VariantFeatureClasses } from './components/variantFeature/variantFeatureStyles';
import { VariantFeaturesClasses } from './components/variantFeatures/variantFeaturesStyles';
import { VariantFeaturesListClasses } from './components/variantFeaturesList/variantFeaturesListStyles';
import { SelectedModelBannerClasses } from './components/selectedModel/selectedModelBannerStyles';
import { GenericCarNameClasses } from '../common/components/presentation/genericCarName/genericCarNameStyles';
import { VariantActionsClasses } from './components/variantActions/variantActionsStyles';
import { SimpleButtonClasses } from '../common/components/presentation/simpleButton/SimpleButtonStyles';
import { ExpandFeaturesClasses } from './components/expandFeatures/expandFeaturesStyles';
import { NavigationBarClasses } from '../gqlCommon/components/navigation/navigationBar/navigationBarStyles';
import { BaseTrimSlideStyles } from '../trimLevels/components/slides/baseTrimSlideStyles';
import { BaseSlideControlClasses } from '../gqlCommon/components/sliders/variantTrimSlider/variantSliderControls/baseSlideControlStyles';

export interface IVariantsThemableComponents {
  PVariants_VariantFeatures: IThemableComponent<VariantFeaturesClasses>;
  PVariants_VariantFeature: IThemableComponent<VariantFeatureClasses>;
  PVariants_VariantFeaturesList: IThemableComponent<VariantFeaturesListClasses>;
  PVariants_SelectedModelBanner: IThemableComponent<SelectedModelBannerClasses>;
  PVariants_GenericCarName: IThemableComponent<GenericCarNameClasses>;
  PVariants_VariantActions: IThemableComponent<VariantActionsClasses>;
  PVariants_ExpandFeatures: IThemableComponent<ExpandFeaturesClasses>;
  Customise_NavigationBar: IThemableComponent<NavigationBarClasses>;
  Common_SimpleButton: IThemableComponent<SimpleButtonClasses>;
  TrimLevels_BaseTrimSlide: IThemableComponent<BaseTrimSlideStyles>;
  Common_BaseSlideControl: IThemableComponent<BaseSlideControlClasses>;
}

const DeferredProductVariantsScreen: UniversalComponent<any> = universal<any>(
  () => import(/* webpackChunkName: "configure-screen" */ './components/ProductVariantsScreen'),
  {
    loading: (): JSX.Element => <MinimalLoader />,
    error: (props: { error: Error }) => {
      errorHandler.handleError(props.error);
      return <ThemedErrorMessage fullPage={true} message={'Failed loading variants screen.'} error={props.error} />;
    },
  },
);

export default DeferredProductVariantsScreen;
