import React from 'react';
import IPlatformConfig from '../../model/IPlatformConfig';
import multiDimensionApi from '../../api/multiDimensionApi';
import PlatformConfigContext from '../../react/PlatformConfigContext';
import IInitializerComponent from './IInitializerComponent';

const PlatformConfigProvider: IInitializerComponent<{ config: IPlatformConfig }> = {
  initialize: () => {
    return multiDimensionApi.getConfiguration().then(config => ({ config }));
  },

  component: ({ children, config }) => (
    <PlatformConfigContext.Provider value={config}>{children}</PlatformConfigContext.Provider>
  ),
};

export default PlatformConfigProvider;
