import React from 'react';
import ReactDOM from 'react-dom';
import { CssResetComponent } from '@inchcape/context-hard-reset.css/react-component';
import IWidgetConfiguration from '../../common/model/IWidgetConfiguration';
import { plainConfigValidator } from './common/service/plainConfigurationValidator';
import widgetFacadeFactory from './common/service/widgetFacadeFactory';
import WIDGET_CONFIG_SCHEMA from './configSchema.json';
import WidgetContext from './common/react/WidgetContext';
import SpcWidget from './common/components/SpcWidget';
import DEFAULT_WIDGET_CONFIG from './common/constants/defaultWidgetConfig';
import './common/assets/spc.css';
import './common/assets/react-toastify.css';
import IframeIosMobileFix from './common/components/IframeIosMobileFixComponent';
import locale from './common/service/locale';
import EventContainer from '../../common/event/EventContainer';
import InitErrorMessage from './common/components/presentation/error/InitErrorMessage';
import GeneralErrorBoundary from './common/components/presentation/error/GeneralErrorBoundary';
import deepmerge from 'deepmerge';

export const widgetLibrary = {
  createWidget: (configuration: IWidgetConfiguration): Promise<any> => {
    return new Promise((resolve: (facade: any) => any) => {
      configuration = deepmerge(DEFAULT_WIDGET_CONFIG, configuration);

      const localeCode = locale.getLocaleCode(configuration.dimensions?.language, configuration.dimensions?.country);
      if (localeCode) {
        configuration = Object.assign(configuration, { locale: localeCode });
      }

      const configValid: true | any = plainConfigValidator.validate(configuration, WIDGET_CONFIG_SCHEMA);
      if (configValid !== true) {
        throw configValid;
      }

      const eventContainer = new EventContainer();
      const facade = widgetFacadeFactory.create(configuration, eventContainer);

      // @TODO: Remove this once Single Scroll Translations are updated
      const consoleError = console.error.bind(console);
      console.error = (message: any, ...args: any) => {
        if (
          typeof message === 'string' &&
          message.startsWith('[React Intl]') &&
          process.env.NODE_ENV === 'development'
        ) {
          return;
        }
        consoleError(message, ...args);
      };

      ReactDOM.render(
        <CssResetComponent className="spc">
          <GeneralErrorBoundary>
            <IframeIosMobileFix />
            <WidgetContext.Provider value={[configuration, eventContainer]}>
              <SpcWidget />
            </WidgetContext.Provider>
          </GeneralErrorBoundary>
        </CssResetComponent>,
        document.getElementById(configuration.containerId),
        () => resolve(facade),
      );
    }).catch(error => {
      ReactDOM.render(
        <CssResetComponent className="spc">
          <IframeIosMobileFix />
          <InitErrorMessage error={error} message={'SPC: Widget orchestration failed.'} />
        </CssResetComponent>,
        document.getElementById(configuration.containerId),
      );

      throw error;
    });
  },
};

export const SPCWidget = Object.freeze(widgetLibrary);
