import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { useEffect } from 'react';
import usePlatformConfig from '../react/usePlatformConfig';

const useAzureMonitor = () => {
  const {
    platformConfig: { azureMonitoring },
  } = usePlatformConfig();

  useEffect(() => {
    if (azureMonitoring) {
      const appInsights = new ApplicationInsights({
        config: azureMonitoring,
      });
      appInsights.loadAppInsights();
      appInsights.trackPageView();
    }
  }, [azureMonitoring]);
};

export default useAzureMonitor;
