import React from 'react';
import {
  createGenerateClassName,
  createMuiTheme,
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
  jssPreset,
} from '@material-ui/core/styles';
import { create } from 'jss';
import increaseSpecificity from 'jss-increase-specificity';
import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';
import CONSTANTS from '../../constants/constants';
import IInitializerComponent from './IInitializerComponent';

import commonConstants from '../../theme/common';
import useWidgetContext from '../../react/useWidgetContext';
import IWidgetConfiguration from '../../../../../common/model/IWidgetConfiguration';
import { assetManager } from '../../../../../common/service/assetManager';

const generateClassName = createGenerateClassName({
  seed: 'SPC_WIDGET',
  productionPrefix: 'PROD',
});

const jss = create({
  ...jssPreset(),
  plugins: [...jssPreset().plugins, increaseSpecificity({ repeat: 1 })],
});

const ThemeProvider: IInitializerComponent<{ theme: any }, { configuration: IWidgetConfiguration }> = {
  hooks: () => {
    const { configuration } = useWidgetContext();

    return { configuration };
  },
  initialize: async args => {
    const {
      configuration: {
        dimensions: { brand, region, country },
      },
    } = args;
    await assetManager.loadStyle(CONSTANTS.CSS_URL);
    const themeModule = await import(
      /* webpackChunkName: "theme.[request]" */ `../../../../../common/${brand}.${country}.${region}.assets/spcBrandTheme`
    );

    return {
      theme: createMuiTheme(themeModule.default, commonConstants),
    };
  },
  component: ({ theme, children }) => (
    <div style={{ margin: 'auto', width: '100%' }}>
      <StylesProvider generateClassName={generateClassName} jss={jss}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />

          <Box color={'text.primary'}>{children}</Box>
        </MuiThemeProvider>
      </StylesProvider>
    </div>
  ),
};

export default ThemeProvider;
