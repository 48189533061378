import React, { FunctionComponent } from 'react';
import { Box, Button } from '@material-ui/core';
import useGqlRestSwitch from '../hooks/useGqlRestSwitch';

const GqlRestSwitch: FunctionComponent = () => {
  const { toggleGqlApi, isGqlApi, isGqlMarket } = useGqlRestSwitch();

  if (!isGqlMarket) {
    return null;
  }

  return (
    <Box display="flex" justifyContent="center" mb={3}>
      <Button variant="outlined" color="primary" onClick={toggleGqlApi} data-test="button:graphql_rest_switch">
        Switch to {isGqlApi ? 'REST' : 'GraphQL'}
      </Button>
    </Box>
  );
};

export default GqlRestSwitch;
