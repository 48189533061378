import React from 'react';
import universal, { UniversalComponent } from 'react-universal-component';
import { OfferBadgeClasses } from './components/car/OfferBadgeStyles';
import { PrimaryPriceClasses } from './components/prices/cashPrice/primaryPriceStyles';
import { SecondaryPriceClasses } from './components/prices/cashPrice/secondaryPriceStyles';
import { CarActionsClasses } from './components/car/carActionsStyles';
import { CarCardClasses } from './components/car/carCard/carCardStyles';
import errorHandler from '../common/service/errorHandler';
import { ButtonOptionToggleClasses } from '../common/components/presentation/Button/buttonOptionToggleStyles';
import { ComparisonRateClasses } from './components/prices/comparisonRateStyles';
import { FinancePricesClasses } from './components/prices/financePriceValue/financePriceValueStyles';
import MinimalLoader from '../common/components/presentation/MinimalLoader';
import ThemedErrorMessage from '../common/components/presentation/error/ThemedErrorMessage';
import IThemableComponent from '../common/theme/IThemableComponent';
import { MarketingLabelClasses } from './components/car/MarketingLabelStyles';
import { TooltipClasses } from './components/car/tooltipStyles';
import { FrequencyWithEditClasses } from '../gqlProductCatalog/components/prices/frequencyWithEdit/frequencyWithEditStyles';

export interface IProductCatalogThemableComponents {
  PCatalog_CarActions: IThemableComponent<CarActionsClasses>;
  PCatalog_CarCard: IThemableComponent<CarCardClasses>;
  SPC_ButtonOptionToggle: IThemableComponent<ButtonOptionToggleClasses>;
  PCatalog_FinancePriceData: IThemableComponent<FinancePricesClasses>;
  PCatalog_FinancePriceComparisonRate: IThemableComponent<ComparisonRateClasses>;
  PCatalog_MarketingLabel: IThemableComponent<MarketingLabelClasses>;
  PCatalog_OfferBadge: IThemableComponent<OfferBadgeClasses>;
  PCatalog_PrimaryPrice: IThemableComponent<PrimaryPriceClasses>;
  PCatalog_SecondaryPrice: IThemableComponent<SecondaryPriceClasses>;
  PCatalog_Tooltip: IThemableComponent<TooltipClasses>;
  PCatalog_FrequencyWithEdit: IThemableComponent<FrequencyWithEditClasses>;
}

const DeferredProductCatalogScreen: UniversalComponent<any> = universal<any>(
  () => import(/* webpackChunkName: "models-screen" */ './components/ProductCatalogScreen'),
  {
    loading: (): JSX.Element => <MinimalLoader />,
    error: (props: { error: Error }) => {
      errorHandler.handleError(props.error);
      return <ThemedErrorMessage fullPage={true} message={'Failed loading models screen.'} error={props.error} />;
    },
  },
);

export default DeferredProductCatalogScreen;
