import React, { Component, ReactNode } from 'react';
import InitErrorMessage from './InitErrorMessage';

/**
 * Component to handled react errors.
 * This is not functional component since only class components support this.
 */
export default class GeneralErrorBoundary extends Component {
  state: {
    error?: any;
  } = {
    error: null,
  };

  public static getDerivedStateFromError(error: any): { error: any } {
    return { error };
  }

  public render(): ReactNode {
    if (this.state.error) {
      return <InitErrorMessage error={this.state.error} message={'React lifecycle error.'} />;
    }

    return this.props.children;
  }
}
