const logger = {
  warn(message: any): void {
    if (process.env.ENVIRONMENT !== 'prod') {
      /* tslint:disable:no-console */
      console.warn(message);
    }
  },

  info(message: any): void {
    if (process.env.ENVIRONMENT !== 'prod') {
      /* tslint:disable:no-console */
      console.info(message);
    }
  },

  log(message: any): void {
    if (process.env.ENVIRONMENT !== 'prod') {
      console.info(message);
    }
  },
};

export default logger;
