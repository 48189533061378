import urlRoute from '../service/urlRoute';
import IDimensions from '../model/IDimensions';

const MISSING_ENV_STR = '{missing_env}';

const PUB_PATH = urlRoute.joinUrl(process.env.SERVER_URL || MISSING_ENV_STR, process.env.REACT_SPC_PUBLIC_PATH);

const CONSTANTS = {
  APP_CONFIG_BASE_URL: process.env.APP_CONFIG_BASE_URL || MISSING_ENV_STR,
  POSTCODE_DIGITS_REGEX: /^(\d{4})$/g,
  POSTCODE_MAX_DIGITS: 4,
  PLATFORM_API_URL: process.env.PLATFORM_API_URL || MISSING_ENV_STR,
  DIGITAL_API_URL: urlRoute.joinUrl(process.env.PLATFORM_API_URL || MISSING_ENV_STR, '/digital-api'),
  SPC_API_URL: process.env.PLATFORM_API_URL + '/spc/api/v1' || MISSING_ENV_STR,
  // TODO: This is temporary as the route for transfer option is in basket domain - might change
  BASKET_API_URL: process.env.PLATFORM_API_URL + '/basket/api/v1' || MISSING_ENV_STR,

  CSS_URL: urlRoute.joinUrl(PUB_PATH, '/library/spc.css'),

  // Currently this locale code displays both currency and decimals in the required format.
  // If a case presents where the USD and decimal need to be displayed differently,
  // the desired locale code should come from the platform configuration.
  USD_CURRENCY_DEFAULT_LOCALE_CODE: 'en-GB',

  CACHE_TTL: process.env.SPC_API_RESPONSE_CACHE_TTL
    ? parseInt(process.env.SPC_API_RESPONSE_CACHE_TTL, 10)
    : 1000 * 60 * 5, // 5 minutes default

  REACT_DEPENDENCIES:
    process.env.NODE_ENV === 'production'
      ? {
          react:
            process.env.AUTOMOTIVE_WIDGETS_REACT_BASE_URL.replace(/^$/, 'https://cdn.jsdelivr.net/npm/react@16/umd') +
            '/react.production.min.js',
          reactDom:
            process.env.AUTOMOTIVE_WIDGETS_REACT_BASE_URL.replace(
              /^$/,
              'https://cdn.jsdelivr.net/npm/react-dom@16/umd',
            ) + '/react-dom.production.min.js',
          react18:
            process.env.DXP_WIDGETS_REACT_BASE_URL.replace(/^$/, 'https://cdn.jsdelivr.net/npm/react@18/umd') +
            '/react.production.min.js',
          reactDom18:
            process.env.DXP_WIDGETS_REACT_BASE_URL.replace(/^$/, 'https://cdn.jsdelivr.net/npm/react-dom@18/umd') +
            '/react-dom.production.min.js',
        }
      : {
          react: 'https://cdn.jsdelivr.net/npm/react@16/umd/react.development.js',
          reactDom: 'https://cdn.jsdelivr.net/npm/react-dom@16/umd/react-dom.development.js',
          react18: 'https://cdn.jsdelivr.net/npm/react@18/umd/react.development.js',
          reactDom18: 'https://cdn.jsdelivr.net/npm/react-dom@18/umd/react-dom.development.js',
        },

  MARKETING_CATEGORY: 'marketingCategory',

  BE_DEFAULT_DIMENSIONS: {
    brand: 'subaru',
    country: 'au',
    region: 'au',
    language: 'en',
  } as IDimensions,

  // TODO: To be removed once we have cars on jaguar brand
  BE_JAG_PL_DIMENSIONS: {
    brand: 'jag-dis',
    country: 'pl',
    region: 'eu',
    language: 'pl-PL',
  } as IDimensions,

  // TODO: To be removed once we have cars on land rover brand
  BE_LRE_FI_DIMENSIONS: {
    brand: 'LR',
    country: 'Finland',
    region: 'Finland',
    language: 'en-GB',
  } as IDimensions,

  BE_SUBDIS_AU_DIMENSIONS: {
    brand: 'sub-dis',
    country: 'au',
    region: 'au',
    language: 'en-au',
  } as IDimensions,

  BE_TOYOTA_HK_DIMENSIONS: {
    brand: 'toy-dis',
    country: 'hk',
    region: 'au',
    language: 'en-hk',
  } as IDimensions,

  BE_SUBARU_PE_DIMENSIONS: {
    brand: 'subaru',
    country: 'pe',
    region: 'latam',
    language: 'es-pe',
  } as IDimensions,

  BE_SUBARU_CL_DIMENSIONS: {
    brand: 'subaru',
    country: 'cl',
    region: 'latam',
    language: 'es-cl',
  } as IDimensions,

  BE_BMW_DIS_CL_DIMENSIONS: {
    brand: 'bmw-dis',
    country: 'cl',
    region: 'latam',
    language: 'es-cl',
  } as IDimensions,

  BE_BMW_DIS_PE_DIMENSIONS: {
    brand: 'bmw-dis',
    country: 'pe',
    region: 'latam',
    language: 'es-pe',
  } as IDimensions,

  BE_SUB_DIS_NZ_DIMENSIONS: {
    brand: 'sub-dis',
    country: 'nz',
    region: 'au',
    language: 'en-nz',
  } as IDimensions,

  BE_HIN_DIS_CO_DIMENSIONS: {
    brand: 'hin-dis',
    country: 'co',
    region: 'latam',
    language: 'es-co',
  } as IDimensions,

  BE_MOT_DIS_CL_DIMENSIONS: {
    brand: 'mot-dis',
    country: 'cl',
    region: 'latam',
    language: 'es-cl',
  } as IDimensions,

  BE_MOT_DIS_PE_DIMENSIONS: {
    brand: 'mot-dis',
    country: 'pe',
    region: 'latam',
    language: 'es-pe',
  } as IDimensions,

  BE_MINI_DIS_CL_DIMENSIONS: {
    brand: 'mini-dis',
    country: 'cl',
    region: 'latam',
    language: 'es-cl',
  } as IDimensions,

  BE_MINI_DIS_PE_DIMENSIONS: {
    brand: 'mini-dis',
    country: 'pe',
    region: 'latam',
    language: 'es-pe',
  } as IDimensions,

  BE_MER_DIS_CO_DIMENSIONS: {
    brand: 'mer-dis',
    country: 'co',
    region: 'latam',
    language: 'es-co',
  } as IDimensions,

  BE_SZK_DIS_PA_DIMENSIONS: {
    brand: 'szk-dis',
    country: 'pa',
    region: 'latam',
    language: 'es-pa',
  } as IDimensions,

  BE_SZK_DIS_CR_DIMENSIONS: {
    brand: 'szk-dis',
    country: 'cr',
    region: 'latam',
    language: 'es-cr',
  } as IDimensions,

  BE_SZK_DIS_SG_DIMENSIONS: {
    brand: 'szk-dis',
    country: 'sg',
    region: 'au',
    language: 'en-sg',
  } as IDimensions,

  BE_TOY_DIS_BN_DIMENSIONS: {
    brand: 'toy-dis',
    country: 'bn',
    region: 'au',
    language: 'en-bn',
  } as IDimensions,

  BE_DFS_DIS_PE_DIMENSIONS: {
    brand: 'dfs-dis',
    country: 'pe',
    region: 'latam',
    language: 'es-pe',
  } as IDimensions,

  BE_DFS_DIS_CL_DIMENSIONS: {
    brand: 'dfs-dis',
    country: 'cl',
    region: 'latam',
    language: 'es-cl',
  } as IDimensions,

  BE_TOY_DIS_SG_DIMENSIONS: {
    brand: 'toy-dis',
    country: 'sg',
    region: 'au',
    language: 'en-sg',
  } as IDimensions,
  BE_TOY_DIS_HK_DIMENSIONS: {
    brand: 'toy-dis',
    country: 'hk',
    region: 'au',
    language: 'en-hk',
  } as IDimensions,
  BE_TOY_DIS_ZH_HK_DIMENSIONS: {
    brand: 'toy-dis',
    country: 'hk',
    region: 'au',
    language: 'zh-hk',
  } as IDimensions,
  BE_SUB_DIS_AU_DIMENSIONS: {
    brand: 'sub-dis',
    country: 'au',
    region: 'au',
    language: 'en-au',
  } as IDimensions,
  BE_GLY_DIS_CL_DIMENSIONS: {
    brand: 'gly-dis',
    country: 'cl',
    region: 'latam',
    language: 'es-cl',
  } as IDimensions,
  BE_GRW_DIS_HK_DIMENSIONS: {
    brand: 'grw-dis',
    country: 'hk',
    region: 'au',
    language: 'en-hk',
  } as IDimensions,
  BE_GRW_DIS_ZH_HK_DIMENSIONS: {
    brand: 'grw-dis',
    country: 'hk',
    region: 'au',
    language: 'zh-hk',
  } as IDimensions,
  BE_SUBARU_AR_DIMENSIONS: {
    brand: 'subaru',
    country: 'ar',
    region: 'latam',
    language: 'es-ar',
  } as IDimensions,
  BE_SUBARU_CO_DIMENSIONS: {
    brand: 'subaru',
    country: 'co',
    region: 'latam',
    language: 'es-co',
  } as IDimensions,
  BE_LEX_DIS_AU_HK_DIMENSIONS: {
    brand: 'lex-dis',
    country: 'hk',
    region: 'au',
    language: 'en-hk',
  } as IDimensions,
  BE_MER_PAS_EC_DIMENSIONS: {
    brand: 'mer-pas',
    country: 'ec',
    region: 'latam',
    language: 'es-ec',
  } as IDimensions,
  BE_BYD_DIS_NL_BE_DIMENSIONS: {
    brand: 'byd-dis',
    country: 'be',
    region: 'eu',
    language: 'nl-be',
  } as IDimensions,
  BE_BYD_DIS_FR_BE_DIMENSIONS: {
    brand: 'byd-dis',
    country: 'be',
    region: 'eu',
    language: 'fr-be',
  } as IDimensions,
  BE_BYD_DIS_EN_BE_DIMENSIONS: {
    brand: 'byd-dis',
    country: 'be',
    region: 'eu',
    language: 'en-be',
  } as IDimensions,
  GTM_ID: process.env.REACT_APP_GTM_ID,
};

export default CONSTANTS;
