import { CustomDxpWindow } from "./custom.window";

declare let window: CustomDxpWindow;

const dxpDataLayerInitializationHandler = (isDxpDataLayer: boolean): void => {

  if (isDxpDataLayer) {
    window.dxpDataLayer = !!isDxpDataLayer;
    window.dataLayer = window.dataLayer || [];
  }
};

export default dxpDataLayerInitializationHandler;
