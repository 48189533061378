import {
  DataLayerEvent,
  DataLayerFormName,
  DataLayerFormStage,
  SpeckPackListType,
} from '../../../../../../common/utilities/customDatalayerInterface';
import digitalDataAssignment from '../../../../../../common/utilities/digitalDataAssignment';
import {
  GetSpecPackTrimLevel,
  GetSpecPackTrimLevel_specPacks,
  GetSpecPackTrimLevel_specPacks_carConfig_prices_carPrice_offers_discounts,
  GetSpecPackTrimLevelVariables,
} from '../../../../trimLevels/graphql/queries/__generated__/GetSpecPackTrimLevel';
import dataLayerToggleVerification from '../../../../../../common/utilities/dataLayerToggleVerification';
import priceExtractionHandler from '../priceExtractionHandler';
import IDimensions from '../../../../gqlCommon/model/IDimensions';
import { customerStorage } from '../../../../../../common/service/customerStorage';

export interface SpeckPackTrimType {
  data: GetSpecPackTrimLevel;
  variables: GetSpecPackTrimLevelVariables;
  dimension: IDimensions;
}

const VariantDataLayer = ({ data, variables, dimension }: SpeckPackTrimType): void => {
  try {
    const isDataLayer = dataLayerToggleVerification(dimension);

    if (isDataLayer) {
      const specPacks: SpeckPackListType[] = [];
      const customerStorageValue = customerStorage.getCustomerData();

      data.specPacks.forEach((item: GetSpecPackTrimLevel_specPacks) => {
        const {
          prices: PriceDetail,
          car: {
            engine: { attributes: engineAttributes },
            model: modelDetail,
            bodyStyle: bodyStyleDetail,
          },
          prices: { carPrice: carPriceDetail },
        } = item.carConfig;

        const discounts: GetSpecPackTrimLevel_specPacks_carConfig_prices_carPrice_offers_discounts =
          PriceDetail.carPrice.offers?.discounts[0];

        const originalPrice = priceExtractionHandler(carPriceDetail.originalPrice, dimension).primaryPrice;
        const finalPrice = priceExtractionHandler(carPriceDetail.finalPrice, dimension).primaryPrice;

        specPacks.push({
          nameplate: {
            code: item.carConfig.car.code,
            name: modelDetail?.attributes?.shortname,
            modelYear: modelDetail?.attributes?.modelyear,
            brand: modelDetail?.attributes?.brand,
            description: modelDetail?.attributes?.longdescription,
          },
          specPack: {
            code: item.specPack?.attributes?.oemcode,
            name: item.specPack?.attributes?.shortname,
          },
          engine: {
            description: engineAttributes?.longdescription,
            fuelType: engineAttributes?.fuelType,
            fuelLabel: engineAttributes?.fuelLabel,
            powerTrain: engineAttributes?.powertrain,
            capacity: engineAttributes?.capacity,
            maximumPower: engineAttributes?.maximumpower,
            enginePower: engineAttributes?.enginepower,
            maximumTorque: engineAttributes?.maximumtorque,
            transmission: engineAttributes?.transmissionLabel,
            transmissionType: engineAttributes?.transmissionType,
          },
          bodyType: {
            bodyStyleType: bodyStyleDetail?.attributes?.shortname,
            description: bodyStyleDetail?.attributes?.longdescription,
            code: bodyStyleDetail.code,
          },
          standardFeatures: item.carConfig.car.keyFeatures.map(item => {
            return {
              code: item.code,
              name: item.shortname,
              id: item.id,
              category: '',
            };
          }),
          addOnFeatures: [],
          prices: [
            {
              carPrice: {
                originalPrice: originalPrice,
                totalDiscount: carPriceDetail?.totalDiscount[0]?.amount,
                finalPrice: finalPrice,
                offers: [{ amount: discounts?.value[0]?.amount }],
              },
              totalPrice: PriceDetail?.totalPrice[0]?.money?.amount,
              currency: PriceDetail?.totalPrice[0]?.money?.currency,
              taxes: [
                {
                  amount: PriceDetail?.carPrice?.finalPrice[1]?.taxes[0]?.money?.amount,
                  type: PriceDetail?.carPrice?.finalPrice[1]?.taxes[0]?.type,
                },
              ],
            },
          ],
        });
      });

      digitalDataAssignment({
        event: DataLayerEvent.FORM_NAVIGATE,
        form: {
          name: DataLayerFormName.CONFIGURATOR,
          stage: DataLayerFormStage.VARIANT_CONFIGURATOR,
          details: {
            configurator: {
              specPacks: specPacks,

              selectedSpec: [
                {
                  trim: {
                    attributes: {
                      label: data.specPacks[0]?.carConfig.car?.trim?.attributes?.shortname,
                      marketingprefix: data.specPacks[0]?.carConfig.car?.trim?.attributes?.marketingprefix,
                    },
                  },
                },
              ],
            },
          },
        },
        user: {
          postCode: variables.postCode,
          preferredDealership: customerStorageValue?.selectedDealer?.label,
        },
      });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('DataLayer error', err);
  }
};

export default VariantDataLayer;
