import * as H from 'history';

const queryParams = {
  getParam: (queryType: string, location: H.Location) => {
    const search = location.search;
    const params = new URLSearchParams(search);

    return params.get(queryType);
  },
  changeParamValue: (
    location: H.Location,
    history: H.History,
    paramName: string,
    oldValue: string,
    newValue: string,
  ) => {
    const oldSearchParams = location.search;
    const newSearchParams = oldSearchParams.replace(`${paramName}=${oldValue}`, `${paramName}=${newValue}`);

    history.replace({
      ...location,
      search: newSearchParams,
    });
  },
};

export default queryParams;
