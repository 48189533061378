import React, { FunctionComponent, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import useWidgetContext from '../react/useWidgetContext';
import CommonEvents from '../../../../common/constants/events';
import RouterExtensionContext from '../react/RouterExtensionContext';
import GqlRestSwitch from './GqlRestSwitch';
import SpcWidgetRoutes from './SpcWidgetRoutes';
import MiniMyAccountComponent from './myAccount/MiniMyAccountComponent';
import usePlatformConfig from '../react/usePlatformConfig';

const SpcWidgetRouter: FunctionComponent = () => {
  const { configuration, eventContainer } = useWidgetContext();
  const {
    platformConfig: { myAccount },
  } = usePlatformConfig();
  const [currentPath, setCurrentPath] = useState<string | null>(null);
  eventContainer.emit(CommonEvents.INITIALIZED);
  return (
    <BrowserRouter basename={configuration.basePath}>
      {process.env.NODE_ENV === 'development' && <GqlRestSwitch />}
      <RouterExtensionContext.Provider value={{ currentPath, setCurrentPath }}>
        {myAccount?.miniMyAccountConfig && <MiniMyAccountComponent />}
        <SpcWidgetRoutes />
      </RouterExtensionContext.Provider>
    </BrowserRouter>
  );
};

export default SpcWidgetRouter;
