import storageService from './storageService';
import { ICustomerData } from '../model/ICustomerData';
import sessionStorageService from "./sessionStorageService";

// We are versioning the customer data in frontend, because if we do it on the backend, then every other application
// which uses customer data needs to be updated. e.g. fin calc, my account, checkouts, basket, etc.
const CUSTOMER_STORAGE: string = 'customer_data_';
export const CUSTOMER_STORAGE_VERSION_NEW = 'v4';
export const CUSTOMER_STORAGE_VERSION_OLD = 'v3';

export const customerStorage = {
  getOldKey(): string {

    return `${CUSTOMER_STORAGE_VERSION_OLD}_${CUSTOMER_STORAGE}`;
  },

  getKey(): string {

    return `${CUSTOMER_STORAGE_VERSION_NEW}_${CUSTOMER_STORAGE}`;
  },

  getCustomerData(): ICustomerData {
    let customerData: ICustomerData | null = null;
    try {
      customerData = JSON.parse(storageService.get(customerStorage.getKey()) ?? '');
    } catch (error) {
      customerData = null;
    }
    return customerData ?? { postcode: '' };
  },

  getCustomerDataSession(): ICustomerData {
    let customerData: ICustomerData | null = null;
    try {
      customerData = JSON.parse(sessionStorageService.get(customerStorage.getKey()) ?? '');
    } catch (error) {
      customerData = null;
    }
    return customerData ?? { postcode: '' };
  },

  setCustomerData(customerData: ICustomerData): void {
   return storageService.set(customerStorage.getKey(), JSON.stringify(customerData));
  },

  setCustomerDataSession(customerData: ICustomerData): void {
    return sessionStorageService.set(customerStorage.getKey(), JSON.stringify(customerData));
  },

  removeCustomerData(): void {
    return storageService.remove(customerStorage.getKey());
  },

  removeOldCustomerData(): void {
    return storageService.remove(customerStorage.getOldKey());
  },

  setPostcode(postcode: string): void {
    const customerData: ICustomerData | null = this.getCustomerData();

    if (customerData && customerData.postcode) {
      return;
    } else if (!customerData || !customerData.postcode || !customerData.selectedDealer) {
      this.setCustomerData({ ...customerData, postcode });
    }
  },

  getPostcode(): string | null {
    const customerData: ICustomerData | null = this.getCustomerData();

    return customerData?.postcode
  }
};
