export default class SpcError extends Error {
  /**
   * The name of the error.
   *
   * @type {string}
   */
  public static code = 'SPC-GENERIC';

  /**
   * Constructs the error.
   *
   * @param {string} message
   */
  constructor(message: string) {
    super(message);

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, SpcError.prototype);
    this.name = this.getCode();
  }

  /**
   * Get the code name of the error.
   *
   * @return {string}
   */
  public getCode(): string {
    return (this.constructor as typeof SpcError).code;
  }
}
