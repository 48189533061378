import httpClient from './httpClient';
import API from '../constants/api';
import IPlatformConfig from '../model/IPlatformConfig';
import priceDisplayConfigTransformer from '../service/priceDisplayConfigTransformer';
import { JourneyType } from '../../__generated__/globalTypes';

const multiDimensionApi = {
  getConfiguration(): Promise<IPlatformConfig> {
    return httpClient.get(API.MULTI_DIMENSION_CONFIG).then((response: any) => {
      const featureFlags: any = Object.values((response.data as any).platformFeatures || {}).pop();

      if (!featureFlags) {
        throw new Error(`Missing SPC feature flags platform configuration.`);
      }

      return {
        spcDealersFileName: featureFlags.spcDealersFileName,
        isVariantsPageEnabled: featureFlags.variantsPage === true,
        isFuelFilteringEnabled: featureFlags.fuelFilter === true,
        isBodyStyleFilteringEnabled: featureFlags.bodyStyleFilter === true,
        isTransmissionFilteringEnabled: featureFlags.transmissionFilter === true,
        isPriceSortingEnabled: featureFlags.carPriceSorting === true,
        isDealerSelectionEnabled: featureFlags.dealerSelectionIntegration === true,
        isSaveOfferEnabled: featureFlags.saveOffer === true,
        isFinanceEnabled: featureFlags.financeIntegration === true,
        isCheckoutLiteEnabled: featureFlags.spcCheckoutLiteIntegration === true,
        showGovernmentCharges: featureFlags.showGovernmentCharges === true,
        hideSubmitWhenNoStock: featureFlags.hideSubmitWhenNoStock === true,
        isComplexModel: featureFlags.complexModel === true,
        gqlApi: featureFlags.gqlApi === true,
        stockPage: featureFlags.stock,
        priceDisplay: priceDisplayConfigTransformer.from(response.data?.priceDisplay),
        catalogTrimFeatures: featureFlags.catalogTrimFeatures,
        engineTrimFeatures: featureFlags.engineTrimFeatures,
        isCheckout3Enabled: featureFlags.isCheckout3Enabled === true,
        isCheckout4Enabled: featureFlags.isCheckout4Enabled === true,
        showThreeSixtyImages: featureFlags.showThreeSixtyImages,
        stockVersion: featureFlags.stockVersion,
        stockInteraction: featureFlags.stockInteraction,
        stockApiPing: featureFlags.stockApiPing === true,
        hourDisableOrderNowFromTo: featureFlags.hourDisableOrderNowFromTo,
        configurator: {
          summaryShowTaxes: response?.configurator30?.summaryShowTaxes,
          defaultCheckoutJourney:
            response?.configurator30?.defaultCheckoutJourney || JourneyType.continue_in_dealership,
        },
        hideSelectionsSection: featureFlags.hideSelectionsSection === true,
        dataLayer: featureFlags.dataLayer === true,
      };
    });
  },
};

export default multiDimensionApi;
