import React, { FC, ReactElement, useMemo } from 'react';

export interface IInitErrorMessageProps {
  message?: string | ReactElement;
  error?: Error;
}

/**
 * Error message component with 0 context requirement.
 */
const InitErrorMessage: FC<IInitErrorMessageProps> = ({ message, error }) => {
  const mainMessage = useMemo(() => {
    if (message) {
      return message;
    } else if (error && error.message && process.env.ENVIRONMENT !== 'prod') {
      return error.message;
    } else {
      return 'Something went wrong!';
    }
  }, [message, error]);

  const secondaryMessage = useMemo(() => {
    if (message && error?.message && process.env.ENVIRONMENT !== 'prod') {
      return error.message;
    } else {
      return '';
    }
  }, [message, error]);

  const makeRulesImportant = (style: string) => {
    // Solution for css reset component having more specificity over the styles.
    return style.replace(/(:[^:]+);/g, '$1 !important;');
  };

  return (
    <>
      <style>
        {makeRulesImportant(`
          // Inline styles are used since we might not have the css loaded.

          .__x15__error_root {
            margin: auto;
            width: 60%;
            max-width: 700px;
            min-width: 200px;
          }
          .__x15__error_header {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
          .__x15__error_iconContainer {
            position: relative;
            padding: 15px;
          }
          .__x15__error_icon {
            display: block;
            position: relative;
            width: 40px;
            height: 40px;
            border: 2px solid #f44336;
            border-radius: 9999px;
          }
          .__x15__error_icon:before, .__x15__error_icon:after {
            content: ' ';
            display: block;
            width: 4px;
            height: 60%;
            position: absolute;
            left: 50%;
            top: 50%;
            background: #f44336;
            border-radius: 2px;
          }
          .__x15__error_icon:before {
            transform: translate(-50%, -50%) rotate(45deg);
          }
          .__x15__error_icon:after {
            transform: translate(-50%, -50%) rotate(-45deg);
          }
          .__x15__error_message {
            font-family: Helvetica;
            font-size: 18px;
            padding: 15px;
            padding-top: 0;
            text-align: center;
            width: 100%;
          }
          .__x15__error_secondaryMessage {
            font-family: Helvetica;
            font-size: 18px;
            margin-top: 4px;
            opacity: 0.7;
            transform: scale(0.9);
          }
        `)}
      </style>
      <div className={'__x15__error_root'}>
        <div className={'__x15__error_header'}>
          <div className={'__x15__error_iconContainer'}>
            <div className={'__x15__error_icon'} />
          </div>
          <div className={'__x15__error_message'}>
            <div data-test={'error:message'}>{mainMessage}</div>
            <div className={'__x15__error_secondaryMessage'} data-test={'error:secondary-message'}>
              {secondaryMessage}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InitErrorMessage;
