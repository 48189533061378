import React from 'react';
import universal from 'react-universal-component';
import errorHandler from '../common/service/errorHandler';
import MinimalLoader from '../common/components/presentation/MinimalLoader';
import ThemedErrorMessage from '../common/components/presentation/error/ThemedErrorMessage';
import IThemableComponent from '../common/theme/IThemableComponent';
import { SummaryMinimalClasses } from './components/customiseContainer/customiseControls/summary/summaryMinimal/summaryMinimalClasses';
import { VariantSelectorItemClasses } from '../common/components/variantSelector/variantSelectorItem/variantSelectorItemStyles';
import { SpecificationsClasses } from './components/customiseContainer/customiseControls/variantSelector/specificationsAndFeatures/specifications/specificationsStyles';
import { KeyFeaturesClasses } from './components/customiseContainer/customiseControls/variantSelector/specificationsAndFeatures/keyFeatures/keyFeaturesStyles';
import { OptionPriceMultiCurrencyClasses } from './components/presentational/prices/multiCurrency/optionPrice/optionPriceMultiCurrencyStyles';
import { SingleCurrencyCashClasses } from './components/presentational/prices/singleCurrency/cash/singleCurrencyCashStyles';
import { FinanceProductTabsClasses } from './components/customiseContainer/footer/PriceSection/FinanceProductTabs/financeProductTabsStyles';
import { PriceWithLabelStylesClasses } from './components/customiseContainer/footer/PriceSection/CarPrices/PriceWithLabel/priceWithLabelStyles';
import { CashFinanceGroupClasses } from './components/customiseContainer/footer/cashFinanceGroup/cashFinanceGroupStyles';
import { AccessoryPackModalClasses } from './components/customiseContainer/customiseControls/accessoryPacks/accessoryPackModal/accessoryPackModalStyles';
import { StickyFooterClasses } from './components/customiseContainer/footer/StickyFooterContainerStyles';
import { ButtonViewDetailsClasses } from '../common/components/sticky/ButtonViewDetailsStyles';
import { FinanceProductGridElementClasses } from './components/customiseContainer/footer/financeProductGridElement/financeProductGridElementStyles';
import { BaseConfiguratorLayoutClasses } from '../common/components/layouts/baseConfiguratorLayout/baseConfiguratorLayoutStyles';
import { ISummaryLinksStylesClasses } from './components/customiseContainer/customiseControls/summary/summaryLinks/SummaryLinksStyles';
import { ActionButtonsClasses } from './components/presentational/actionButtons/ActionButtonsStyles';
import { SliderDotsClasses } from './components/customiseContainer/customiseDisplay/variantSlider/sliderDots/sliderDotsStyles';
import { DisclaimerSectionClasses } from './components/customiseContainer/disclaimer/disclaimerSectionStyles';
import { featureSectionTitleClass } from '../gqlCustomise/components/customiseContainer/customiseControls/featureSection/featureSectionTitle/featureSectionTitleStyles';
import { FeatureAccessoryClasses } from '../gqlCustomise/components/customiseContainer/customiseControls/features/featureAccessory/featureAccessoryStyles';
import { IPriceValueClasses } from '../gqlCustomise/components/customiseContainer/customiseControls/summary/summaryContent/summaryPrices/priceValue/PriceValueStyles';
import { ISummaryLabelClasses } from '../gqlCustomise/components/customiseContainer/customiseControls/summary/summaryLabel/SummaryLabelStyles';
import { TrimLevelNameClasses } from '../common/components/trimLevelsName/trimLevelName/trimLevelNameStyles';
import { featureOptionClasses } from '../gqlCustomise/components/customiseContainer/customiseControls/features/featureOption/featureOptionStyle';
import { ISummaryStyleClasses } from './components/customiseContainer/customiseControls/summary/summarySection/SummaryStyles';
import { OptionPriceSingleCurrencyClasses } from './components/presentational/prices/singleCurrency/optionPrice/optionPriceSingleCurrencyStyles';
import { ModelSelectorBaseClasses } from '../gqlCustomise/components/customiseContainer/customiseControls/modelSelector/modelSectionContainer/modelSelectorBase/modelSelectorStyles';
import { CustomiseSectionTitleClasses } from '../gqlCustomise/components/customiseContainer/customiseControls/simpleBrands/customiseSectionTitle/customiseSectionTitleStyles';
import { FeatureAccessoryDetailsModalClasses } from '../gqlCustomise/components/customiseContainer/customiseControls/features/featureAccessory/featureAccessoryDetailsModal/featureAccessoryDetailsModalStyles';
import { VariantSelectorBaseClasses } from '../gqlCustomise/components/customiseContainer/customiseControls/variantsSection/variantSelectorBase/variantSelectorStyles';
import { SpecificationsAccordionContentClasses } from '../gqlCustomise/components/customiseContainer/customiseControls/specifications/specificationsAccordionContent/specificationsAccordionContentStyles';
import { SpecificationsDetailItemStylesClasses } from '../gqlCustomise/components/customiseContainer/customiseControls/specifications/specificationsDetailItem/specificationsDetailItemStyles';
import { ModelSelectorItemClasses } from '../gqlCustomise/components/customiseContainer/customiseControls/modelSelector/modelSelectorItem/modelSelectorItemStyles';
import { SpecificationsAndFeaturesClasses } from '../gqlCustomise/components/customiseContainer/customiseControls/specificationsAndFeatures/specificationsAndFeaturesStyles';
import { VariantSliderClasses } from './components/customiseContainer/customiseDisplay/variantSlider/sliderImage/SliderImageStyles';
import { SliderTitleOfferClasses } from './components/customiseContainer/customiseDisplay/variantSlider/sliderTitleOffer/sliderTitleOfferStyles';
import { SliderTitleClasses } from './components/customiseContainer/customiseDisplay/variantSlider/sliderTitle/sliderTitleStyles';
import { IFitImageStyleClasses } from '../common/components/image/fitImageStyles';
import { CalculateButtonClasses } from '../customise/components/presentational/financeElements/calculateButton/calculateButtonStyles';
import { StockStatusClasses } from '../gqlCustomise/components/customiseContainer/customiseDisplay/variantSlider/stockStatus/stockStatusStyles';
import { NavigationElementClasses } from '../gqlCommon/components/navigation/navigationElement/navigationElementStyles';
import { ThreeSixtyViewToggleClasses } from '../gqlCustomise/components/customiseContainer/customiseDisplay/threeSixtyViewToggle/threeSixtyViewToggleStyles';
import { Image360Classes } from '../gqlCustomise/components/customiseContainer/customiseDisplay/image360/image360Styles';
import { StockSliderClasses } from '../gqlCustomise/components/StockLite/stockSlider/stockSliderStyles';
import { PanoramicInteriorClasses } from '../gqlCustomise/components/customiseContainer/customiseDisplay/panoramicInterior/panoramicInteriorStyles';
import { StockLiteSliderClasses } from '../gqlCustomise/components/StockLite/stockLiteListStyle';
import { CarCardStylesClasses } from '../gqlCustomise/components/StockLite/CarCard/carCardStyles';
import { ISummaryAdditionalSelectionsClasses } from '../gqlCustomise/components/customiseContainer/customiseControls/summary/summaryAdditionalSelections/summaryAdditionalSelectionsStyles';
import { InformativeSectionContainerClasses } from './components/customiseContainer/footer/PriceSection/informativeSection/informativeSectionContainerStyles';
import { SliderTitleDiscountClasses } from '../gqlCustomise/components/customiseContainer/customiseDisplay/variantSlider/sliderTitleDiscount/sliderTitleDiscountStyles';
import { FeatureCategoryClasses } from '../gqlCustomise/components/customiseContainer/customiseControls/featureCategories/featureCategory/featureCategoryStyles';
import { FeatureAccessoryListClasses } from '../gqlCustomise/components/customiseContainer/customiseControls/features/featureAccessory/featureAccessoryList/featureAccessoryListStyles';
import { CardContentClasses } from '../stock/components/stockList/carCard/cardContent/cardContentStyles';

export interface ICustomiseThemableComponents {
  Customise_NavigationElement: IThemableComponent<NavigationElementClasses>;
  Customise_SummaryMinimal: IThemableComponent<SummaryMinimalClasses>;
  Customise_VariantSelectorItem: IThemableComponent<VariantSelectorItemClasses>;
  Customise_Specifications: IThemableComponent<SpecificationsClasses>;
  Customise_KeyFeatures: IThemableComponent<KeyFeaturesClasses>;
  Customise_OptionPriceMultiCurrency: IThemableComponent<OptionPriceMultiCurrencyClasses>;
  Customise_SingleCurrencyCash: IThemableComponent<SingleCurrencyCashClasses>;
  Customise_FinanceProductTabs: IThemableComponent<FinanceProductTabsClasses>;
  Customise_PriceWithLabel: IThemableComponent<PriceWithLabelStylesClasses>;
  Customise_ActionButtons: IThemableComponent<ActionButtonsClasses>;
  Customise_CashFinanceGroup: IThemableComponent<CashFinanceGroupClasses>;
  Customise_AccessoryPackModal: IThemableComponent<AccessoryPackModalClasses>;
  Customise_StickyFooterContainer: IThemableComponent<StickyFooterClasses>;
  Customise_SliderDotsStyles: IThemableComponent<SliderDotsClasses>;
  StickySummary_ButtonViewDetails: IThemableComponent<ButtonViewDetailsClasses>;
  Customise_FinanceProductGridElement: IThemableComponent<FinanceProductGridElementClasses>;
  Common_BaseConfiguratorLayout: IThemableComponent<BaseConfiguratorLayoutClasses>;
  Customise_SummaryLinks: IThemableComponent<ISummaryLinksStylesClasses>;
  Customise_DisclaimerSection: IThemableComponent<DisclaimerSectionClasses>;
  Customise_Feature_Section_Title: IThemableComponent<featureSectionTitleClass>;
  Customise_Feature_Accessory: IThemableComponent<FeatureAccessoryClasses>;
  Customise_PriceValue: IThemableComponent<IPriceValueClasses>;
  Customise_PriceLabel: IThemableComponent<ISummaryLabelClasses>;
  Customise_TrimLevelName: IThemableComponent<TrimLevelNameClasses>;
  Customise_Feature_Option: IThemableComponent<featureOptionClasses>;
  Customise_Summary: IThemableComponent<ISummaryStyleClasses>;
  Customise_OptionPriceSingleCurrency: IThemableComponent<OptionPriceSingleCurrencyClasses>;
  Customise_ModelSelector: IThemableComponent<ModelSelectorBaseClasses>;
  Customise_simpleBrands_CustomiseSectionTitle: IThemableComponent<CustomiseSectionTitleClasses>;
  Customise_Feature_Accessory_DetailsModal: IThemableComponent<FeatureAccessoryDetailsModalClasses>;
  Common_VariantSelector: IThemableComponent<VariantSelectorBaseClasses>;
  Customise_SpecificationsAccordionContent: IThemableComponent<SpecificationsAccordionContentClasses>;
  Customise_SpecificationsDetailItemStyles: IThemableComponent<SpecificationsDetailItemStylesClasses>;
  Customise_ModelSelectorItemClasses: IThemableComponent<ModelSelectorItemClasses>;
  Customise_SpecificationsAndFeatures: IThemableComponent<SpecificationsAndFeaturesClasses>;
  Customise_SliderImage: IThemableComponent<VariantSliderClasses>;
  Customise_SliderTitle: IThemableComponent<SliderTitleClasses>;
  Customise_SliderTitleOffer: IThemableComponent<SliderTitleOfferClasses>;
  Customise_sliderTitleDiscount: IThemableComponent<SliderTitleDiscountClasses>;
  SPC_FitImage: IThemableComponent<IFitImageStyleClasses>;
  Customise_CalculateButton: IThemableComponent<CalculateButtonClasses>;
  Customise_StockStatus: IThemableComponent<StockStatusClasses>;
  Customise_ThreeSixtyViewToggle: IThemableComponent<ThreeSixtyViewToggleClasses>;
  GqlCustomise_Image360: IThemableComponent<Image360Classes>;
  GqlCustomise_StockSlider: IThemableComponent<StockSliderClasses>;
  Customise_PanoramicInterior: IThemableComponent<PanoramicInteriorClasses>;
  GqlCustomise_StockList: IThemableComponent<StockLiteSliderClasses>;
  StockLite_CarCard: IThemableComponent<CarCardStylesClasses>;
  StockLite_CardContent: IThemableComponent<CardContentClasses>;
  Customise_SummaryAdditionalSelections: IThemableComponent<ISummaryAdditionalSelectionsClasses>;
  Customise_InformativeSectionContainer: IThemableComponent<InformativeSectionContainerClasses>;
  Customise_Feature_Category: IThemableComponent<FeatureCategoryClasses>;
  Customise_Feature_AccessoryList: IThemableComponent<FeatureAccessoryListClasses>;
}

const DeferredCustomiseContainer = universal(
  () => import(/* webpackChunkName: "customise" */ './components/customiseContainer/CustomiseContainer'),
  {
    loading: (): JSX.Element => <MinimalLoader />,
    error: (props: { error: Error }) => {
      errorHandler.handleError(props.error);
      return <ThemedErrorMessage fullPage={true} message={'Failed loading customise screen.'} error={props.error} />;
    },
  },
);

export default DeferredCustomiseContainer;
