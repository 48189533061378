import {
  DataLayerEvent,
  DataLayerFormName,
  DataLayerFormStage,
} from '../../../../../../common/utilities/customDatalayerInterface';
import {
  GetTrims,
  GetTrims_catalog_nodes,
  GetTrimsVariables,
} from '../../../../gqlProductCatalog/graphql/queries/__generated__/GetTrims';
import digitalDataAssignment from '../../../../../../common/utilities/digitalDataAssignment';
import dataLayerToggleVerification from '../../../../../../common/utilities/dataLayerToggleVerification';
import priceExtractionHandler from '../priceExtractionHandler';
import IDimensions from '../../../../gqlCommon/model/IDimensions';
import { customerStorage } from '../../../../../../common/service/customerStorage';

export interface GetTrimsType {
  data: GetTrims;
  variables: GetTrimsVariables;
  dimension: IDimensions;
}
const CatalogDataLayer = ({ data, variables, dimension }: GetTrimsType): void => {
  try {
    const isDataLayer = dataLayerToggleVerification(dimension);
    if (isDataLayer) {
      const customerStorageValue = customerStorage.getCustomerData();
      const modelList = data.catalog.nodes.map((item: GetTrims_catalog_nodes) => {
        const { finalPrice: carConfigFinalPrice } = item.carConfig.prices.carPrice;
        const carPrice = priceExtractionHandler(carConfigFinalPrice, dimension).primaryPrice;

        return {
          model: item.trim.attributes.label,
          driveAwayPricing: carPrice,
          manufacturerListPricing: carPrice,
        };
      });

      digitalDataAssignment({
        event: DataLayerEvent.FORM_NAVIGATE,
        form: {
          name: DataLayerFormName.CONFIGURATOR,
          stage: DataLayerFormStage.CATALOG,
          details: {
            configurator: {
              financeOption: data.catalog.nodes[0]?.carConfig.finance.type,
              modelList: modelList,
            },
          },
        },
        user: {
          postCode: variables.postCode,
          preferredDealership: customerStorageValue?.selectedDealer?.label,
        },
      });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('DataLayer error', err);
  }
};
export default CatalogDataLayer;
