import React, { FunctionComponent, ReactElement, useEffect, useMemo, useState } from 'react';
import IInitializerComponent from './providers/IInitializerComponent';
import errorHandler from '../service/errorHandler';
import InitErrorMessage from './presentation/error/InitErrorMessage';

interface IParallelContextualizedInitializerProps {
  initializers: Array<IInitializerComponent<any, any>>;
  loader?: React.ReactNode;
}

const ParallelContextualizedInitializer: FunctionComponent<IParallelContextualizedInitializerProps> = ({
  initializers,
  children,
  loader,
}) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [initializedProps, setInitializedProps] = useState([]);

  const initializationResults = initializers.map(initializer => {
    const hookResult = typeof initializer.hooks === 'function' ? initializer.hooks() : {};

    return useMemo(() => initializer.initialize(hookResult), Object.values(hookResult));
  });

  useEffect(() => {
    Promise.all(initializationResults)
      .then(props => {
        setInitializedProps(props);
        setLoaded(true);
      })
      .catch(error => {
        errorHandler.handleError(error);
        setError(error);
      });
  }, [...initializationResults]);

  if (error) {
    return <InitErrorMessage message={'SPC: Failed to initialize widget.'} error={error} />;
  }

  if (!loaded) {
    return loader ? <>{loader}</> : <div>...</div>;
  }

  const renderTree = (index = 0): ReactElement => {
    if (index === initializers.length) {
      return <>{children}</>;
    }

    const Component = initializers[index].component;

    return <Component {...initializedProps[index]}>{renderTree(++index)}</Component>;
  };

  return renderTree();
};

export default ParallelContextualizedInitializer;
