export const ROUTES = {
  INDEX: '/',
  MODELS: '/models',
  CONFIGURE_OLD: '/configure-old/:modelId/:carId',
  CONFIGURE_BASE: '/configure/',
  CONFIGURE: '/configure/:modelId/:carId',
  CONFIGURE_GQL: '/configure/:trimCode',
  STOCK_LIST: '/configure/:modelId/:carId/stock/list',
  STOCK_LIST_GQL: '/configure/:trimCode/stock/list',
  STOCK_DETAIL: '/configure/:modelId/:carId/stock/details/:externalId',
  STOCK_DETAIL_GQL: '/configure/:trimCode/stock/details/:externalId',
  STOCK_DETAIL_FROM_CUSTOMISE: '/stock/details',

  VARIANTS_BASE: '/variants/',
  VARIANTS_MARKETING_CATEGORY: '/variants/:modelId/:marketingCategory',
  VARIANTS_MARKETING_CATEGORY_GQL: '/variants/:trimCode/:marketingCategory',
  VARIANTS: '/variants/:modelId/:marketingCategory?',
  VARIANTS_GQL: '/variants/:trimCode/:marketingCategory?',

  TRIM_LEVELS_BASE: '/trim-levels/',
  TRIM_LEVELS: '/trim-levels/:trimCode/:activeTrim?',
};
