import { ApolloClient, ApolloError, ApolloLink, createHttpLink, from, gql } from '@apollo/client';
import IDimensions from '../model/IDimensions';
import cache from './cache';
import { tokenService } from '../../../../common/service/tokenService';
import dataLayerInterceptor from '../../common/service/dataLayerInterceptor';
import dataLayerToggleVerification from '../../../../common/utilities/dataLayerToggleVerification';
import dxpDataLayerToggleVerification from '../../../../common/utilities/dxpDataLayerToggleVerification';

const { onError } = require('@apollo/client/link/error');

export type CustomGraphqlError = {
  code?: string;
  message?: string;
};

type ErrorHandlerType = {
  graphQLErrors: ApolloError[];
};

const typeDefs = gql`
  type FeatureSection {
    id: String
    value: Boolean
  }

  type SelectedFeatureInput {
    selected: [String]
    added: [String]
    removed: [String]
  }

  extend type CatalogResult {
    financeFormInput: FinanceFormInput
    selectedCarNextFilters: Filters
  }

  extend type CustomizedCarConfiguration {
    financeFormInput: FinanceFormInput
    selectedCurrency: String
    selectedSections: [FeatureSection]
    selectedFeaturesId: [String]
    selectedFeaturesInput: SelectedFeatureInput
  }

  extend type TrimLevelVariants {
    financeFormInput: FinanceFormInput
    marketingCategories: [String]
  }
  extend type EngineResult {
    financeFormInput: FinanceFormInput
  }
  extend type BodyStyleResult {
    financeFormInput: FinanceFormInput
  }
  extend type ModelResult {
    financeFormInput: FinanceFormInput
  }
  extend type SpecPackResult {
    financeFormInput: FinanceFormInput
  }
`;

const interceptGraphqlResponse = (dimension: IDimensions) => {
  return new ApolloLink((operation, forward) => {
    return forward(operation).map((response: any) => {
      if (response.data) {
        const isDataLayer = dataLayerToggleVerification(dimension);
        const dxpDataLayer = dxpDataLayerToggleVerification();
        if (isDataLayer) {
          dataLayerInterceptor.interceptor({
            operationName: operation.operationName,
            response: response.data,
            variables: operation.variables,
            dimension: dimension,
          });
        }

        if (dxpDataLayer) {
          dataLayerInterceptor.dxpInterceptor({
            operationName: operation.operationName,
            response: response.data,
            dimensions: dimension,
            dxpDataLayer,
          });
        }
      }

      return response;
    });
  });
};

const generateApolloClient = (dimensions: IDimensions, headers: Record<string, string> = {}) => {
  const sessionErrorCodes: Array<string | undefined> = ['SESSION_NOT_FOUND', 'SESSION_INVALID', 'SESSION_EXPIRED'];
  const errorLink = onError(({ graphQLErrors }: ErrorHandlerType) => {
    if (graphQLErrors) {
      if (Array.isArray(graphQLErrors)) {
        graphQLErrors.forEach(graphQLError => {
          if (sessionErrorCodes.includes((graphQLError as CustomGraphqlError).code)) {
            tokenService.removeCurrentToken(dimensions);
          }
        });
      } else if (sessionErrorCodes.includes((graphQLErrors as CustomGraphqlError).code)) {
        tokenService.removeCurrentToken(dimensions);
      }
    }
  });
  const httpLink = (dimensions: IDimensions) =>
    createHttpLink({
      uri: process.env.GRAPHQL_SERVER_URL,
      headers: {
        'X-Brand': dimensions.brand,
        'X-Language': dimensions.language,
        'X-Region': dimensions.region,
        'X-Country': dimensions.country,
        ...headers,
      },
    });
  const apolloClientFromLinks = [interceptGraphqlResponse(dimensions), errorLink, httpLink(dimensions)];

  return new ApolloClient({
    link: from(apolloClientFromLinks),
    cache,
    typeDefs,
    // Assume that if there are extra headers we have some kind of session. In such case abort caching.
    defaultOptions:
      Object.keys(headers).length > 0
        ? {
            query: {
              fetchPolicy: 'network-only',
            },
            watchQuery: {
              fetchPolicy: 'network-only',
            },
          }
        : {},
  });
};

export default generateApolloClient;
