import IDimensions from '../model/IDimensions';
import { IPriceDisplay } from '../model/IPlatformConfig';

function buildId(dimension: IDimensions): string {
  return `${dimension.country}_${dimension.brand}_priceDisplay`;
}

export const priceDisplaySpc = {
  get: (dimension: IDimensions) => {
    return localStorage.getItem(buildId(dimension)) ?? 'null';
  },
  set: (dimension: IDimensions, value: IPriceDisplay) => {
    localStorage.setItem(buildId(dimension), JSON.stringify(value));
  },
};
