import React, { FunctionComponent, useCallback, useState } from 'react';
import useSalesEnablementCommunication from '../../common/hooks/useSalesEnablementCommunication';
import SalesEnablementContext from '../context/SalesEnablementContext';
import useRefs from '../hooks/useRefs';

const SalesEnablementContextProvider: FunctionComponent = ({ children }) => {
  const [firstDealerOptionIndex, setFirstDealerOptionIndex] = useState<number>(-1);

  const { refs, register } = useRefs();

  const scrollToDealerOption = useCallback(() => {
    if (firstDealerOptionIndex !== -1) {
      refs[`panel_${firstDealerOptionIndex}`]?.current.setExpanded();
      refs[`panel_${firstDealerOptionIndex}`]?.current.scrollIntoView({
        behavior: 'auto',
        block: 'start',
      });
    }
  }, [firstDealerOptionIndex, refs]);

  const { customizeVersion, initializedWithStock, isInSalesEnablement, sendIsInCustomisePage, sessionId } =
    useSalesEnablementCommunication(scrollToDealerOption);

  return (
    <SalesEnablementContext.Provider
      value={{
        customizeVersion,
        featureCategoryRefs: refs,
        initializedWithStock,
        isInSalesEnablement,
        registerFeatureCategoryRef: register,
        sendIsInCustomisePage,
        sessionId,
        setFirstDealerOptionIndex,
      }}
    >
      {children}
    </SalesEnablementContext.Provider>
  );
};

export default SalesEnablementContextProvider;
