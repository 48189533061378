import React from 'react';
import { IntlProvider } from 'react-intl';
import useWidgetContext from '../../react/useWidgetContext';
import IInitializerComponent from './IInitializerComponent';
import IWidgetConfiguration from '../../../../../common/model/IWidgetConfiguration';
import { translations as getTranslations } from '@inchcape/automotive-core-platform-sdk-package/dist/repository/translations';

const TranslationProvider: IInitializerComponent<
  { messages: Record<string, string> },
  { configuration: IWidgetConfiguration }
> = {
  hooks: () => {
    return useWidgetContext();
  },

  initialize: args => {
    const translationConfig = {
      withCredentials: !!+process.env.REACT_APP_WITH_CREDENTIALS,
      multiDimensions: args.configuration.dimensions,
      baseURL: process.env.PLATFORM_API_BASE_URL,
      apiBasePath: process.env.PLATFORM_API_URL.replace(process.env.PLATFORM_API_BASE_URL, ''),
      cmsApiPath: '/cms/api',
    };

    return Promise.all([getTranslations('spc', translationConfig), getTranslations('common', translationConfig)]).then(
      ([spcTranslations, commonTranslations]) => {
        return { messages: { ...spcTranslations, ...commonTranslations } as Record<string, string> };
      },
    );
  },

  component: ({ messages, children }) => {
    const { configuration } = useWidgetContext();

    return (
      <IntlProvider locale={configuration.dimensions.language} messages={messages}>
        {children}
      </IntlProvider>
    );
  },
};

export default TranslationProvider;
