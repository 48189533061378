import IDimensions from '../model/IDimensions';

function buildId(dimensions: IDimensions): string {
  return `${dimensions.country}_${dimensions.brand}_finance_form_input`;
}

export const financeFormInputStorage = {
  get: (dimensions: IDimensions) => {
    return JSON.parse(localStorage.getItem(buildId(dimensions)));
  },

  save: (dimensions: IDimensions, data: any) => {
    localStorage.setItem(buildId(dimensions), JSON.stringify(data));
  },
};
