import React from 'react';
import universal from 'react-universal-component';
import errorHandler from '../../../gqlCommon/service/errorHandler';
import ThemedErrorMessage from '../../../common/components/presentation/error/ThemedErrorMessage';
import MinimalLoader from '../../../common/components/presentation/MinimalLoader';

const DeferredStockListContainer = universal(() => import(/* webpackChunkName: "stock" */ './StockListContainer'), {
  loading: (): JSX.Element => <MinimalLoader />,
  error: (props: { error: Error }) => {
    errorHandler.handleError(props.error);
    return <ThemedErrorMessage fullPage={true} message={'Failed loading stock list screen.'} error={props.error} />;
  },
});

export default DeferredStockListContainer;
