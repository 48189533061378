export const getReferrer = (): { from: string, to: string } => {
  const referrer = localStorage.getItem('referrer');
  const initial = { from: '', to: '' };
  if (referrer) {
    const parsed = JSON.parse(referrer);

    return parsed;
  }

  return initial;
}