import dxpDataLayerToggleVerification from '../../../../../../common/utilities/dxpDataLayerToggleVerification';
import { CreateDealFromCarId } from '../../../../gqlCustomise/graphql/mutations/__generated__/CreateDealFromCarId';
import dxpDigitalDataAssignment from '../../../../../../common/utilities/dxpDigitalDataAssignment';
import { DxpDataLayerEventNames } from '../../../../../../common/utilities/customDatalayerInterface';
import dxpDataLayerFeatureService from '../../../../../../common/utilities/dxpDataLayerFeatureService';

export interface CreateDealDataLayerTypes {
  data: CreateDealFromCarId;
}

const CreateDealDataLayer = ({ data }: CreateDealDataLayerTypes): void => {
  try {
    const isDxpDataLayer = dxpDataLayerToggleVerification();
    if (isDxpDataLayer) {
      const {
        car: {
          model,
          bodyStyle,
          engine,
          specPack,
          exterior,
          interior,
          prices: {
            carPrice: { originalPrice },
            totalPrice,
            totalFeatures,
          },
          selectedFeatures,
        },
        finance,
      } = data.createDealFromCarId;
      const simulation = finance?.simulation;
      const accessories = dxpDataLayerFeatureService.getAccessories(selectedFeatures);
      const servicePlan = dxpDataLayerFeatureService.getServicePlan(selectedFeatures);

      dxpDigitalDataAssignment({
        eventName: DxpDataLayerEventNames.CONFIGURATOR_QUOTE,
        pageTitle: document.title,
        model: model?.attributes?.shortname || null,
        motor: engine?.attributes?.shortname || null,
        versions: specPack?.attributes?.shortname || null,
        exteriorColor: exterior?.shortname || null,
        interiorColor: interior?.shortname || null,
        accessories: accessories || [],
        transmission: engine?.attributes?.transmissionLabel || null,
        bodyType: bodyStyle?.attributes?.shortname || null,
        engineType: engine?.attributes?.shortname || null,
        seatCapacity: null,
        options: null,
        servicePlan: servicePlan,
        paymentMethod: finance?.type,
        creditType: simulation?.financeProductName || null,
        vehiclePrice: originalPrice[0]?.money?.amount || null,
        accessoriesPrice: totalFeatures[0]?.money?.amount || null,
        totalPrice: totalPrice[0]?.money?.amount || null,
        downPayment: simulation?.deposit[0]?.amount || null,
        downPaymentPercentage: simulation?.attributes?.depositAmountPercentage || null,
        paymentPeriod: simulation?.loanTermPeriod?.value || null,
        financeAmount: simulation?.amountPayable[0]?.amount || null,
        residualValue: simulation?.attributes?.residualAmount?.[0].amount || null,
        flatRate: simulation?.interestRate || null,
        interestRate: simulation?.interestRate || null,
        comparisonRate: simulation?.comparisonRate || null,
        monthlyPayment: simulation?.paymentValue[0]?.amount || null,
      });
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('DataLayer error', err);
  }
};
export default CreateDealDataLayer;
