import RequestCancelledError from '../../common/error/RequestCancelledError';
import logger from '../../common/service/logger';
import axios from 'axios';
import { isApolloError } from '@apollo/client';

const errorHandler = {
  /**
   * @param error
   * @return boolean
   *   Whether the error was handled.
   */
  handleError(error: any): boolean {
    if (axios.isCancel(error) || error?.name === RequestCancelledError.code) {
      logger.warn(error.message);
      return true;
    }
    if (error && isApolloError(error)) {
      if (error.graphQLErrors) {
        error.graphQLErrors.forEach(({ message, path }) =>
          /* tslint:disable:no-console */
          console.error(`[GraphQL error]: Message: ${message}, Path: ${path}`),
        );
      }
      if (error.networkError) {
        /* tslint:disable:no-console */
        console.error(`[Network error]: ${error.networkError}`);
      }

      return false;
    }

    /* tslint:disable:no-console */
    console.error(error);
    return false;
  },

  promise(): (error: any) => void {
    return error => errorHandler.handleError(error);
  },
};

export default errorHandler;
