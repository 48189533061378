import { useContext } from 'react';
import IPlatformConfig from '../model/IPlatformConfig';
import PlatformConfigContext from './PlatformConfigContext';
import PlatformJsonnetContext from './PlatformJsonnetContext';

export default function usePlatformConfig(): { platformConfig: IPlatformConfig } {
  const configuration: IPlatformConfig = useContext(PlatformConfigContext);
  const newConfiguration: IPlatformConfig = useContext(PlatformJsonnetContext);

  if (!configuration && !newConfiguration) {
    throw new Error(`Missing platform config from context.`);
  }

  return { platformConfig: configuration || newConfiguration };
}
