import IWidgetConfiguration from '../../../../common/model/IWidgetConfiguration';
import EventContainer from '../../../../common/event/EventContainer';
import { useContext } from 'react';
import WidgetContext from './WidgetContext';

export default function useWidgetContext(): {
  configuration: IWidgetConfiguration;
  eventContainer: EventContainer;
} {
  const [configuration, eventContainer] = useContext(WidgetContext);

  if (!configuration || !eventContainer) {
    throw new Error(`Incomplete react widget context.`);
  }

  return { configuration, eventContainer };
}
