import { useLocation } from 'react-router';
import { utmEnumsDetailsInput } from '../enum/UtmParamsTypes';
import sessionStorageService from '../../../../common/service/sessionStorageService';
import { utmSessionName } from '../../../../common/constants/utm';
import queryParams from './queryParams';
import useWidgetContext from '../react/useWidgetContext';

const useCollectedUtm = (): void => {
  const {
    configuration: { dimensions },
  } = useWidgetContext();
  const location = useLocation();
  const { brand, country } = dimensions;
  const utmDetails = Object.assign({}, utmEnumsDetailsInput);

  for (const [key, value] of Object.entries(utmEnumsDetailsInput)) {
    const utmValue = queryParams.getParam(value, location);
    utmDetails[key] = utmValue || '';
  }

  const saveData = Object.values(utmDetails).some((value: string) => value?.trim().length > 0);

  if (saveData) {
    sessionStorageService.set(`${utmSessionName}_${brand}_${country}`, JSON.stringify(utmDetails));
  }
};

export default useCollectedUtm;
