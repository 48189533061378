import useWidgetContext from '../react/useWidgetContext';
import generateApolloClient from '../../gqlCommon/graphql/generateApolloClient';
import useSalesEnablementCommunication from './useSalesEnablementCommunication';

const useApolloClient = () => {
  const { configuration } = useWidgetContext();
  const { sessionId } = useSalesEnablementCommunication();

  const client = generateApolloClient(
    configuration.dimensions,
    null !== sessionId
      ? {
          ['X-SE-Session' as string]: sessionId,
        }
      : {},
  );

  return { client };
};

export default useApolloClient;
