import { useEffect, useState, useCallback } from 'react';
import { financeFormInputVar } from '../../gqlCommon/graphql/cache';
import { financeFormInputStorage } from '../../../../common/service/financeFormInputStorage';
import useWidgetContext from '../react/useWidgetContext';
import { FinanceFormInput } from '../../__generated__/globalTypes';
import IDimensions from '../../gqlCommon/model/IDimensions';

export enum SalesEnablementEventTypes {
  RELOAD_CUSTOMIZE = 'DXP_RELOAD_CUSTOMIZE',
  IS_CUSTOMIZE_PAGE = 'DXP_IS_CUSTOMIZE_PAGE',
  REQUEST_SESSION_ID = 'DXP_REQUEST_SE_SESSION_ID',
  SESSION_ID = 'DXP_SE_SESSION_ID',
  WAS_INITIALIZED_WITH_STOCK = 'DXP_REQUEST_WAS_INITIALIZED_WITH_STOCK',
  INITIALIZED_WITH_STOCK = 'DXP_WAS_INITIALIZED_WITH_STOCK',
  REQUEST_FINANCE_FORM_INPUT = 'DXP_REQUEST_SE_FINANCE_FORM_INPUT',
  FINANCE_FORM_INPUT = 'DXP_SE_FINANCE_FORM_INPUT',
  SELECT_DEALER_OPTIONS = 'DXP_SELECT_DEALER_OPTIONS',
}

const saveFinanceFormInputPayload = (dimensions: IDimensions, payload: string) => {
  try {
    const newFinanceFormInput: FinanceFormInput = JSON.parse(payload);
    financeFormInputVar(newFinanceFormInput);
    financeFormInputStorage.save(dimensions, newFinanceFormInput);
  } catch (e) {
    console.error(e);
  }
};

export default function useSalesEnablementCommunication(onSelectDealerOptions?: () => void): {
  sendIsInCustomisePage: (data: boolean) => void;
  isInSalesEnablement: boolean;
  customizeVersion: number;
  sessionId?: string;
  initializedWithStock: boolean;
} {
  const isInIframe = window !== top || (window as any).__CYPRESS_IS_IN_IFRAME;
  const isInSalesEnablement = isInIframe;
  const { configuration } = useWidgetContext();
  const [sessionId, setSessionId] = useState<string | null>(null);
  const [initializedWithStock, setInitializedWithStock] = useState<boolean>(false);
  const [customizeVersion, setCustomizeVersion] = useState(0);

  const sendIsInCustomisePage = useCallback((data: boolean) => {
    window.parent.postMessage(
      {
        type: SalesEnablementEventTypes.IS_CUSTOMIZE_PAGE,
        payload: data,
      },
      '*',
    );
  }, []);

  useEffect(() => {
    if (!isInSalesEnablement) {
      return;
    }

    const onMessage = (message: MessageEvent) => {
      if (message.data.type === SalesEnablementEventTypes.RELOAD_CUSTOMIZE) {
        setCustomizeVersion(state => ++state);
      }
      if (message.data.type === SalesEnablementEventTypes.SESSION_ID) {
        setSessionId(message.data.payload);
      }
      if (message.data.type === SalesEnablementEventTypes.INITIALIZED_WITH_STOCK) {
        setInitializedWithStock(!!message.data.payload);
      }
      if (message.data.type === SalesEnablementEventTypes.FINANCE_FORM_INPUT) {
        const { payload } = message.data;
        if (payload && payload !== 'null' && payload !== 'false') {
          saveFinanceFormInputPayload(configuration.dimensions, payload);
        }
      }
      if (message.data.type === SalesEnablementEventTypes.SELECT_DEALER_OPTIONS) {
        onSelectDealerOptions && onSelectDealerOptions();
      }
    };

    window.addEventListener('message', onMessage);
    window.parent.postMessage({ type: SalesEnablementEventTypes.REQUEST_SESSION_ID }, '*');
    window.parent.postMessage({ type: SalesEnablementEventTypes.WAS_INITIALIZED_WITH_STOCK }, '*');
    window.parent.postMessage({ type: SalesEnablementEventTypes.REQUEST_FINANCE_FORM_INPUT }, '*');

    return () => window.removeEventListener('message', onMessage);
  }, [onSelectDealerOptions]);

  return {
    sendIsInCustomisePage,
    isInSalesEnablement,
    customizeVersion,
    sessionId,
    initializedWithStock: isInSalesEnablement && initializedWithStock,
  };
}
