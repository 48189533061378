import React from 'react';
import universal, { UniversalComponent } from 'react-universal-component';
import { OfferBadgeClasses } from './components/car/OfferBadgeStyles';
import { PrimaryPriceClasses } from './components/prices/cashPrice/primaryPriceStyles';
import { SecondaryPriceClasses } from './components/prices/cashPrice/secondaryPriceStyles';
import { CarActionsClasses } from './components/car/carActionsStyles';
import { CarCardClasses } from './components/car/carCard/carCardStyles';
import { CarGroupClasses } from './components/car/carGroup/carGroupStyles';
import errorHandler from '../gqlCommon/service/errorHandler';
import { ButtonOptionToggleClasses } from '../common/components/presentation/Button/buttonOptionToggleStyles';
import { ModalWithHeaderClasses } from '../common/components/presentation/modalWithHeaderStyles';
import { ComparisonRateClasses } from './components/prices/comparisonRateStyles';
import { FinancePricesClasses } from './components/prices/financePriceValue/financePriceValueStyles';
import { FinancePriceDisplayClasses } from './components/prices/financePriceDisplay/financePriceDisplayStyles';
import MinimalLoader from '../common/components/presentation/MinimalLoader';
import ThemedErrorMessage from '../common/components/presentation/error/ThemedErrorMessage';
import IThemableComponent from '../common/theme/IThemableComponent';
import { MarketingLabelClasses } from './components/car/MarketingLabelStyles';
import { SpecificationFiltersClasses } from './components/filters/specificationFilter/specificationFilter.styles';
import { TopBannerClasses } from './components/topBanner/topBannerStyles';
import { FiltersGridClasses } from '../gqlCommon/components/filtersGrid/filtersGrid.styles';
import { FiltersTagsGridClasses } from './components/filters/filterTagsGrid/filterTagsGridStyles';
import { VariantSliderClasses } from '../common/components/variantSlider/variantSliderStyles';
import { CarPricesContainerClasses } from './components/car/carPricesContainerStyles';
import { BaseSlideControlClasses } from '../gqlCommon/components/sliders/variantTrimSlider/variantSliderControls/baseSlideControlStyles';
import { SettingsEditClasses } from './components/prices/calculationEditStyles';
import { ButtonWithIconClasses } from '../common/components/presentation/Button/buttonWithIconStyles';
import { EditButtonClasses } from '../common/components/presentation/Button/editButtonStyles';
import { ModalPaperClasses } from '../common/components/presentation/modalPaperStyles';
import { CarGridClasses } from './components/car/carGridStyles';

export interface IProductCatalogThemableComponents {
  PCatalog_CarActions: IThemableComponent<CarActionsClasses>;
  PCatalog_CarCard: IThemableComponent<CarCardClasses>;
  PCatalog_CarGroup: IThemableComponent<CarGroupClasses>;
  SPC_ButtonOptionToggle: IThemableComponent<ButtonOptionToggleClasses>;
  SPC_ModalWithHeader: IThemableComponent<ModalWithHeaderClasses>;
  PCatalog_FinancePriceData: IThemableComponent<FinancePricesClasses>;
  PCatalog_FinancePriceComparisonRate: IThemableComponent<ComparisonRateClasses>;
  PCatalog_MarketingLabel: IThemableComponent<MarketingLabelClasses>;
  PCatalog_OfferBadge: IThemableComponent<OfferBadgeClasses>;
  PCatalog_CarPricesContainer: IThemableComponent<CarPricesContainerClasses>;
  PCatalog_PrimaryPrice: IThemableComponent<PrimaryPriceClasses>;
  PCatalog_SecondaryPrice: IThemableComponent<SecondaryPriceClasses>;
  PCatalog_SpecificationFilter: IThemableComponent<SpecificationFiltersClasses>;
  PCatalog_TopBanner: IThemableComponent<TopBannerClasses>;
  PCatalog_Filters: IThemableComponent<FiltersGridClasses>;
  PCatalog_FiltersTagsGrid: IThemableComponent<FiltersTagsGridClasses>;
  PCatalog_CarGrid: IThemableComponent<CarGridClasses>;
  Common_VariantSlider: IThemableComponent<VariantSliderClasses>;
  Common_BaseSlideControl: IThemableComponent<BaseSlideControlClasses>;
  ConfigSummary_FinancePriceDisplay: IThemableComponent<FinancePriceDisplayClasses>;
  SPC_CalculationEditBlock: IThemableComponent<SettingsEditClasses>;
  SPC_EditSettingsButton: IThemableComponent<EditButtonClasses>;
  SPC_ButtonWithIcon: IThemableComponent<ButtonWithIconClasses>;
  SPC_ModalPaper: IThemableComponent<ModalPaperClasses>;
}

const DeferredProductCatalogScreen: UniversalComponent<any> = universal<any>(
  () => import(/* webpackChunkName: "models-screen" */ './components/ProductCatalogScreen'),
  {
    loading: (): JSX.Element => <MinimalLoader />,
    error: (props: { error: Error }) => {
      errorHandler.handleError(props.error);
      return <ThemedErrorMessage fullPage={true} message={'Failed loading models screen.'} error={props.error} />;
    },
  },
);

export default DeferredProductCatalogScreen;
