import React, { useCallback, useState } from 'react';
import IInitializerComponent from './IInitializerComponent';
import { Provider } from 'react-redux';
import { Store } from 'redux';
import { IStore } from '../../store';
import StoreUtilsContext from '../../react/StoreUtilsContext';

const StoreProvider: IInitializerComponent<{ storeFactory: () => Store<IStore>; store: Store<IStore> }> = {
  initialize: () =>
    import(`../../store`).then((module: any) => ({
      store: module.default,
      storeFactory: module.storeFactory,
    })),

  component: ({ storeFactory, store: initStore, children }) => {
    const [store, setStore] = useState(initStore);

    const resetStore = useCallback(() => {
      setStore(storeFactory());
    }, [storeFactory]);
    return (
      <StoreUtilsContext.Provider value={{ resetStore }}>
        <Provider store={store}>{children}</Provider>
      </StoreUtilsContext.Provider>
    );
  },
};

export default StoreProvider;
