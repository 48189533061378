import React, { FunctionComponent } from 'react';
import styles from './MinimalLoader.scss';

const MinimalLoader: FunctionComponent = () => {
  return (
    <div
      className={styles.root}
      style={{
        width: '100%',
        fontSize: '30px',
        textAlign: 'center',
      }}
    >
      ...
    </div>
  );
};

export default MinimalLoader;
