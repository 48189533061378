import React from 'react';
import { IntlProvider } from 'react-intl';
import IInitializerComponent from '../../../common/components/providers/IInitializerComponent';
import useWidgetContext from '../../../common/react/useWidgetContext';
import { GetTranslations } from '../../graphql/queries/__generated__/GetTranslations';
import { useGqlTranslations } from '../../hooks/queries/useGqlTranslations';

const GqlTranslationsProvider: IInitializerComponent<{ messages: Record<string, string> }, { data: GetTranslations }> =
  {
    hooks: () => {
      const { data } = useGqlTranslations();

      return { data };
    },
    initialize: args => {
      const { data } = args;

      const messages: Record<string, string> = data?.translations.reduce((acc, curr) => {
        return { ...acc, [curr.code]: curr.value };
      }, {});

      return Promise.resolve({ messages });
    },

    component: ({ messages, children }) => {
      const { configuration } = useWidgetContext();

      return (
        <IntlProvider locale={configuration.dimensions.language} messages={messages}>
          {children}
        </IntlProvider>
      );
    },
  };

export default GqlTranslationsProvider;
