import { servicePlanTypes } from '../constants/servicePlanTypes';

interface SelectedFeatures {
  shortname: string;
  categoryCode: string | null;
}

const featureCategoryCodes = [
  servicePlanTypes.BMW_CL_SERVICE_PLAN,
  servicePlanTypes.SUB_AU_SERVICE_PLAN,
  servicePlanTypes.MINI_CL_SERVICE_PLAN,
];

const dxpDataLayerFeatureService = {
  getAccessories(selectedFeatures: SelectedFeatures[]): string[] {
    return selectedFeatures.reduce((accessories, data) => {
      if (!featureCategoryCodes.includes(data.categoryCode)) {
        accessories.push(data.shortname);
      }

      return accessories;
    }, []);
  },
  getServicePlan(selectedFeatures: SelectedFeatures[]): string {
    return (
      selectedFeatures.find(data => {
        return featureCategoryCodes.includes(data.categoryCode);
      })?.shortname || null
    );
  },
};

export default dxpDataLayerFeatureService;
