import { createContext } from 'react';

interface IRouterExtensionContext {
  currentPath: string | null;
  setCurrentPath: (path: string) => any;
}

const RouterExtensionContext = createContext<IRouterExtensionContext | null>(null);

export default RouterExtensionContext;
