import RequestCancelledError from '../error/RequestCancelledError';
import logger from './logger';
import axios from 'axios';

const errorHandler = {
  /**
   * @param error
   * @return boolean
   *   Whether the error was handled.
   */
  handleError(error: any): boolean {
    if (axios.isCancel(error) || error.name === RequestCancelledError.code) {
      logger.warn(error.message);
      return true;
    }
    /* tslint:disable:no-console */
    console.error(error);
    return false;
  },

  promise(): (error: any) => void {
    return error => errorHandler.handleError(error);
  },
};

export default errorHandler;
